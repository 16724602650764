import { isObject } from './jsUtilities';

export const addClassModifier = (className, classModifier) =>
	classModifier ? `${className}--${classModifier}` : '';

const conditionnallyAddClassModifier = (className, classModifier) =>
	Object.keys(classModifier)
		.map((modifierString) =>
			classModifier[modifierString]
				? addClassModifier(className, modifierString)
				: ''
		)
		.join(' ');

export const classWithModifiers = (className, modifiers) => {
	if (!modifiers) return className;
	/* eslint-disable no-param-reassign */
	if (!Array.isArray(modifiers)) modifiers = [modifiers];
	const classes = [
		className,
		...modifiers.map((modifier) =>
			isObject(modifier)
				? conditionnallyAddClassModifier(className, modifier)
				: addClassModifier(className, modifier)
		)
	];
	return classes.join(' ').replace(/\s\s+/g, ' ').trim();
};
