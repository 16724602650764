import ReactSelect, { StylesConfig } from 'react-select';
import React, { ComponentProps } from 'react';
import { Option } from '../../api/model';
import { statusOptions } from '../../pages/wizard/Container/ContainerForm/status/statusOptions';
import { tagsOptions } from '../../pages/wizard/Container/ContainerForm/tags/tagsOptions';

type StatusSelectProps = {
	onChange: (value: Option) => void;
	value: string;
};
export const StatusSelect = (
	props: Omit<ComponentProps<typeof ReactSelect>, 'onChange'> &
		StatusSelectProps
) => {
	const options = [{ value: '', label: 'Tout afficher' }, ...statusOptions];
	return (
		<Select
			{...props}
			options={options}
			placeholder={<>Statut</>}
			onChange={(v: unknown) => props.onChange(v as Option)}
			value={statusOptions.find((o) => o.value === props.value)}
		/>
	);
};

type TagSelectProps = {
	onChange: (value: Option[]) => void;
};
export const TagsSelect = (
	props: Omit<ComponentProps<typeof ReactSelect>, 'onChange'> & TagSelectProps
) => {
	return (
		<Select
			{...props}
			options={tagsOptions}
			isMulti={true}
			placeholder={<>Tags</>}
			onChange={(values: unknown) => props.onChange(values as Option[])}
		/>
	);
};

export const genericSelectStyles: StylesConfig = {
	placeholder: (baseStyles) => ({
		...baseStyles,
		fontSize: '1.4rem',
		fontWeight: '500'
	}),
	control: (baseStyles) => ({
		...baseStyles,
		border: 'none',
		background: '#f4f7f9',
		marginTop: '1em'
	})
};
export const Select = (props: ComponentProps<typeof ReactSelect>) => {
	return <ReactSelect styles={genericSelectStyles} {...props} />;
};
