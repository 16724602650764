import { commonHeaders } from './utils';
import { captureException, setContext } from '@sentry/react';
import dayjs from 'dayjs';
import { fetchAndTrack } from './tracker';
import { clearLocalStorage } from './clearLocalStorage';
import { FetchArguments } from './model';
import { types } from 'sass';
import Error = types.Error;

const authPrefixUrl = process.env.REACT_APP_AUTH_API;

export const fetchWithAuthentication = (url: string, options = {}) => {
	const fetchArguments = (): FetchArguments => [
		url,
		{ ...options, cache: 'no-store', headers: { ...commonHeaders() } }
	];

	let promise;

	setContext('fetchWithAuthentication', {
		accessExpireAt: localStorage.getItem('access_expire_at'),
		accessExpireAtValid: dayjs().isBefore(
			dayjs(parseInt(localStorage.getItem('access_expire_at')!) * 1000)
		),
		url
	});

	if (
		localStorage.getItem('access_expire_at') &&
		dayjs().isBefore(
			dayjs(parseInt(localStorage.getItem('access_expire_at')!) * 1000)
		)
	) {
		const [url, options, name] = fetchArguments();
		promise = fetchAndTrack(url, options, name);
	} else {
		promise = refreshToken(fetchArguments()).then(() => {
			const [url, options, name] = fetchArguments();
			return fetchAndTrack(url, options, name);
		});
	}

	return promise
		.then((response) => {
			if (response.status >= 400) {
				//We want to keep the 403 into sentry
				if (response.status === 403)
					captureException('fetchWithAuthenticationError' + response.status, {
						contexts: {
							response,
							fetchArguments
						} as any
					});

				const error: Error & { status?: number } = new Error(
					'fetchWithAuthenticationError ' + response.status
				);
				error.status = response.status;
				throw error;
			}

			return response.json();
		})
		.catch((e) => {
			//Only errors 401,403 must return the user to home. Don't forger to log the 403 Exception into sentry
			if (e.status === 401) {
				clearLocalStorage();
				window.location.pathname = '/login';
			}
		});
};

function refreshToken(fetchArguments: FetchArguments) {
	if(localStorage.getItem('refresh_token') === null){
		return Promise.reject({
			status: 401
		})
	}
	return fetchAndTrack(`${authPrefixUrl}/refresh`, {
		method: 'POST',
		headers: {
			'content-type': 'application/json'
		},
		body: JSON.stringify({
			refreshToken: localStorage.getItem('refresh_token')
		})
	})
		.then((response) => response.json())
		.then(({ data }) => {
			localStorage.setItem('access_expire_at', data.accessExpireAt);
			localStorage.setItem('access_token', data.accessToken);
		})
		.then(() =>
			fetchAndTrack(fetchArguments[0], fetchArguments[1], fetchArguments[2])
		);
}
