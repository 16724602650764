import Icon from '../../../../components/Icons/Icon';
import { ICONS } from '../../../../components/Icons/icons';
import { Button } from '../../../../lib/Buttons/Button';
import Drawer from '../../../../components/Drawer/Drawer';
import {
	Container,
	SensorContextType
} from '../../../../components/Context/ContextType';
import { useSensorContext } from '../../../../components/Context/Context';
import { isContainerReadOnlyOrganization } from '../../../../domain/organization-rule';
import './ContainerDrawer.scss';
import { ActiveContractsList } from './ActiveContractsList';
import { useOriginCreateContainerContext } from '../ContainerForm/OriginCreateContainerContext';

type ContainerDrawerProps = {
	container: Container;
	onEdit: () => void;
	onConfirm: () => void;
};
export const ContainerDrawer = ({
	container,
	onEdit,
	onConfirm
}: Readonly<ContainerDrawerProps>) => {
	const { fromWizard } = useOriginCreateContainerContext();
	const sensorContext: SensorContextType = useSensorContext();
	const canEditContainer = !isContainerReadOnlyOrganization(
		sensorContext.getUserOrganization()
	);

	return (
		<div id="container-drawer">
			<Drawer>
				<>
					<div className="container-drawer-content">
						<img
							src={
								container?.picture
									? container.picture + '?width=72'
									: '/images/placeholder_container.svg'
							}
							alt=""
						/>
						<div className="drawer__container-details">
							<div className="drawer_container_header">
								<h3> {container.name}</h3>

								{canEditContainer && (
									<button type="button" className="edit" onClick={onEdit}>
										<Icon iconPath={ICONS.EDIT} />
									</button>
								)}
							</div>

							{!canEditContainer && (
								<p>
									Si vous souhaitez modifier ce contenant, veuillez vous rendre
									sur votre application iSmart.
								</p>
							)}
							<div>
								<ActiveContractsList contracts={container.activeContracts} />
							</div>
						</div>
					</div>
					{fromWizard && (
						<Button type="button" label="Confirmer" onClick={onConfirm} />
					)}
				</>
			</Drawer>
		</div>
	);
};
