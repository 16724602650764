export default function LowNetwork() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="44"
			height="39"
			viewBox="0 0 44 39"
		>
			<g
				id="Groupe_1015"
				data-name="Groupe 1015"
				transform="translate(-37 -123)"
			>
				<g
					id="Groupe_1006"
					data-name="Groupe 1006"
					transform="translate(-154.092 -120.278)"
				>
					<rect
						id="Rectangle_378"
						data-name="Rectangle 378"
						width="7"
						height="19"
						transform="translate(191.092 263.278)"
						fill="#ff4858"
					/>
					<rect
						id="Rectangle_380"
						data-name="Rectangle 380"
						width="7"
						height="39"
						transform="translate(228.092 243.278)"
						fill="#adadad"
					/>
					<rect
						id="Rectangle_381"
						data-name="Rectangle 381"
						width="8"
						height="30"
						transform="translate(209.092 252.278)"
						fill="#adadad"
					/>
				</g>
			</g>
		</svg>
	);
}
