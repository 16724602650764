export default function MediumNetwork() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="44"
			height="39"
			viewBox="0 0 44 39"
		>
			<g
				id="Groupe_1005"
				data-name="Groupe 1005"
				transform="translate(-191.286 -243.447)"
			>
				<rect
					id="Rectangle_378"
					data-name="Rectangle 378"
					width="7"
					height="19"
					transform="translate(191.286 263.447)"
					fill="#ffc96b"
				/>
				<rect
					id="Rectangle_380"
					data-name="Rectangle 380"
					width="7"
					height="39"
					transform="translate(228.286 243.447)"
					fill="#adadad"
				/>
				<rect
					id="Rectangle_381"
					data-name="Rectangle 381"
					width="8"
					height="30"
					transform="translate(209.286 252.447)"
					fill="#ffc96b"
				/>
			</g>
		</svg>
	);
}
