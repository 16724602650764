import React from 'react';
import {classWithModifiers} from "../../utils/css";
// import {classWithModifiers} from '../../../utils/css'

// export const Checkbox = ({label, value, classModifiers, message}) => {
export const Checkbox = (props) => {
	const {label, value, classModifiers, message, checked} = props
	return (
		// Checkbox
		<label
			className={classWithModifiers('hl-checkbox', [...(classModifiers || []),])}
		>
			<input
				id="checkboxId"
				className="hl-checkbox__input"
				type="checkbox"
				value={value}
				defaultChecked={checked}
			/>
			<span className="hl-checkbox__label">{label && (label)}</span>
			{message && (
				<span className="hl-checkbox__message">{message}</span>
			)}
		</label>
		// ---
	);
}
