import React from 'react';
import Routes from './pages/index';
import './lib/scss/app.scss';
import './App.scss';
import { SensorContextProvider } from './components/Context/Context';
import { OfflineScreen } from './components/OfflineScreen/OfflineScreen';

function App() {
	return (
		<OfflineScreen>
			<div className="App">
				<SensorContextProvider>
					<Routes />
				</SensorContextProvider>
			</div>
		</OfflineScreen>
	);
}

export default App;
