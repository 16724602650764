import { getContainerModelList } from '../../../../../api';
import { useEffect, useState } from 'react';
import { useOriginCreateContainerContext } from '../OriginCreateContainerContext';

export const useModels = () => {
	const { organizationId } = useOriginCreateContainerContext();

	const [modelList, setModelList] = useState<any[]>([]);
	const [nextUrl, setNextUrl] = useState<string>(
		`/v2/organizations/${organizationId}/container_models?with=brand&order=[default]=desc&order[brand.name]=asc&order[name]=asc&itemsPerPage=100`
	);
	const getContainerModelListData = (nextUrl: string) => {
		getContainerModelList(organizationId, nextUrl).then(
			({ containerModelList: data, next }) => {
				nextUrl ? setModelList([...modelList, ...data]) : setModelList(data);
				setNextUrl(next);
			}
		);
	};

	useEffect(
		() => {
			if (nextUrl) {
				getContainerModelListData(nextUrl);
			}
		}, // eslint-disable-next-line
		[nextUrl]
	);

	return modelList;
};
