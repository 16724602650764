const mapping = {
	BIO_WASTE: 'Bio-déchet',
	BOTTLING: 'Flaconnage',
	CARDBOARD: 'Carton',
	CIGARETTE: 'Cigarette',
	COMMON_BASKET: 'Poubelle',
	COMMON: 'Ordure ménagère',
	COMPOST: 'Compost',
	GLASS: 'Verre',
	GREEN: 'Déchet Vert',
	LIQUID: 'Liquide',
	OTHER: 'Autre',
	PAPER: 'Papier/Carton',
	PAPER_ONLY: 'Papier',
	RECYCLABLE: 'Emballage',
	TEXTILE: 'Textile'
} as const;

export const getFlowLabel = (key: keyof typeof mapping) => {
	return mapping[key] ?? key;
};
