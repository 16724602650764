import './WizardStepper.scss';
const numberOfSteps = 9;

type WizardStepperProps = {
	activeIndex: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
};

export default function WizardStepper({ activeIndex }: WizardStepperProps) {
	let steps = [];

	for (let i = 0; i < numberOfSteps; i++) {
		const className = i === activeIndex - 1 ? 'active' : '';
		steps.push(<li key={i} className={className}></li>);
	}
	return <ul id="WizardStepper">{steps}</ul>;
}
