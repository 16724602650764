import { Option } from '../../../../../api/model';

export const convertGroupToOption = (group: any): Option | undefined => {
	if (!group) {
		return;
	}
	return {
		value: group.id,
		label: group.name
	};
};
