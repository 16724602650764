import Loading from '../../../../components/FabButton/Loading/Loading';
import { ContainerListItem } from './ContainerListItem';
import { useFlows } from '../ContainerForm/flows/useFlows';
import './ContainerList.scss';
import { MouseEvent } from 'react';

type ContainerListTypes = {
	containers?: any[];
	onSelect: (container: any) => void;
};
export const Containers = ({
	containers,
	onSelect
}: Readonly<ContainerListTypes>) => {
	const flows = useFlows();
	if (!containers) {
		return <Loading />;
	}

	return (
		<ul id="containers_list">
			{containers.map((container) => {
				return (
					<li
						key={container.id}
						role="button"
						tabIndex={0}
						onClick={(e: MouseEvent) => {
							e.stopPropagation();
							onSelect(container);
						}}
					>
						<ContainerListItem container={container} flows={flows} />
					</li>
				);
			})}
		</ul>
	);
};
