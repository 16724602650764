import { ClientSideApiPayload } from './model';
import { fetchAll, fetchOnePage } from './utils';
import { ApiGetFlow } from './model/container';

const generateFlowsUrl = (organisationId: string): string => {
	return `/v2/organizations/${organisationId}/flows`;
};

/**
 * Méthode pour récupérer tous les Flow
 *
 * @param organisationId l'identifiant de l'organiation pour laquelle nous souhaitons récupérer les flows
 * @param url l'url finale vers la ressource de l'organisation. Elle contiendra les informations de la page souhaitée
 * @param previousDepositPoints un tableau de **flow** qui sera utilisé pour agréger l'ensemble de la donnée
 */
export const fetchAllFlows = fetchAll(getFlows);

/**
 * Cette méthode est en charge de récupérer une page de **flow** . Elle sera exécutée pour récupérer
 * plusieurs pages.
 *
 * @param organisationId l'identifiant de l'organiation pour laquelle nous souhaitons récupérer les flow
 * @param url l'url finale vers la ressource de l'organisation
 */
export function getFlows(
	organizationId: string,
	url = generateFlowsUrl(organizationId)
): Promise<ClientSideApiPayload<ApiGetFlow>> {
	return fetchOnePage(`${process.env.REACT_APP_DATA_API}${url}`);
}
