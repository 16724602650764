export default function Warning() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25.41"
			height="23.229"
			viewBox="0 0 25.41 23.229"
		>
			<g id="warning" transform="translate(0 -20.882)">
				<g
					id="Groupe_1007"
					data-name="Groupe 1007"
					transform="translate(0 20.882)"
				>
					<path
						id="Tracé_604"
						data-name="Tracé 604"
						d="M219.531,333.382a1.306,1.306,0,1,0,0,2.612,1.306,1.306,0,0,0,0-2.612Z"
						transform="translate(-206.826 -317.059)"
					/>
					<path
						id="Tracé_605"
						data-name="Tracé 605"
						d="M24.791,41.833a4.459,4.459,0,0,0,.01-4.513l-8.18-14.166A4.507,4.507,0,0,0,8.8,23.149L.607,37.331a4.524,4.524,0,0,0,3.918,6.78H20.863A4.5,4.5,0,0,0,24.791,41.833Zm-1.776-1.024a2.468,2.468,0,0,1-2.157,1.248H4.519a2.435,2.435,0,0,1-2.131-1.222,2.472,2.472,0,0,1-.005-2.492l8.19-14.176a2.46,2.46,0,0,1,4.273.005l8.185,14.176A2.439,2.439,0,0,1,23.015,40.809Z"
						transform="translate(0 -20.882)"
					/>
					<path
						id="Tracé_606"
						data-name="Tracé 606"
						d="M218.733,157.213a1.42,1.42,0,0,0-1.008,1.426c.031.413.057.831.089,1.243.089,1.572.178,3.113.266,4.685a.957.957,0,0,0,.977.919.977.977,0,0,0,.977-.951c0-.324,0-.622.031-.951.057-1.008.12-2.016.178-3.024.031-.653.089-1.306.12-1.959a1.613,1.613,0,0,0-.12-.653A1.309,1.309,0,0,0,218.733,157.213Z"
						transform="translate(-206.352 -150.057)"
					/>
				</g>
			</g>
		</svg>
	);
}
