import LowNetwork from '../../svg/low-network';
import MediumNetwork from '../../svg/medium-network';
import HighNetwork from '../../svg/high-network';

type SignalStrengthIconProps = {
	signalStrength: number;
};

export default function SignalStrengthIcon({
	signalStrength
}: SignalStrengthIconProps) {
	const signalStrengthStatus =
		signalStrength < 2 ? (
			<LowNetwork />
		) : signalStrength === 2 ? (
			<MediumNetwork />
		) : (
			<HighNetwork />
		);
	return signalStrengthStatus;
}
