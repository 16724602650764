import React, {
	ChangeEvent,
	useEffect,
	useLayoutEffect,
	useState
} from 'react';
//styles
import './ContainerForm.scss';
import NextButton from '../../../../components/NextButton/NextButton';
import { InputText, Label } from '../../../../lib/Forms/InputText/InputText';
//context

import { Select } from '../../../../components/select';
import { Option } from '../../../../api/model';
import { DepositSelect } from './deposit/DepositSelect';
import { hasDepositPoints } from '../../../../api/depositPoints';
import Loading from '../../../../components/FabButton/Loading/Loading';
import { useOriginCreateContainerContext } from './OriginCreateContainerContext';
import { StatusSelect } from './status/StatusSelect';
import { TagsCheckboxGroup } from './tags/TagsCheckboxGroup';
import { convertFlowToOption } from './flows/convertFlowToOption';
import { convertGroupToOption } from './groups/convertGroupToOption';
import { convertModelToOption } from './models/convertModelToOption';
import { createLogger } from '../../../../monitoring/logrocket';
import { Button } from '../../../../lib/Buttons/Button';

const logger = createLogger('ContainerForm');

export type FormContainer = {
	name: string;
	group?: Option;
	model?: Option;
	flow?: Option;
	deposit?: Option;
	statut?: Option;
	tags?: string[];
};

type ContainerFormTypes = {
	container: FormContainer;
	setContainer: (container: FormContainer) => void;
	onSave: (container: FormContainer) => Promise<void>;
	models: any[];
	groups: any[];
	flows: any[];
	tags?: boolean;
	onCancel?: () => void;
};
export const ContainerForm = ({
	container,
	setContainer,
	models,
	groups,
	flows,
	onSave,
	tags = false,
	onCancel
}: ContainerFormTypes) => {
	const [hasDP, setHasDP] = useState(false);
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);

	const { organizationId } = useOriginCreateContainerContext();

	useEffect(() => {
		hasDepositPoints(organizationId)
			.then(setHasDP)
			.then(() => setLoading(false));
	}, [organizationId]);

	const groupsOptions = groups.map(convertGroupToOption);
	const modelsOptions = models?.map(convertModelToOption);
	const flowsOptions = flows.map(convertFlowToOption);

	// We disabled this ESLint rule, because we want to be sure that the callback is executed only one time.
	/* eslint-disable react-hooks/exhaustive-deps */
	useLayoutEffect(() => {
		if (saving) {
			onSave(container).then(() =>
				logger.log(`Container ${container.name} saved`)
			);
		}
	}, [saving]);

	const goNext = async () => {
		setSaving(true);
	};

	const isFormInvalid =
		!container.name || !container.group || !container.model || !container.flow;

	if (loading) {
		return <Loading />;
	}
	return (
		<form>
			<InputText
				label="Nom du container"
				value={container.name}
				onChange={(e: ChangeEvent<HTMLInputElement>) =>
					setContainer({ ...container, name: e.target.value })
				}
			/>

			<Label label="Groupes">
				<Select
					placeholder="Groupes"
					options={groupsOptions}
					value={container.group}
					onChange={(value: unknown) =>
						setContainer({ ...container, group: value as Option })
					}
				></Select>
			</Label>

			<Label label="Model">
				<Select
					options={modelsOptions}
					value={container.model}
					onChange={(value: unknown) =>
						setContainer({ ...container, model: value as Option })
					}
				></Select>
			</Label>

			<Label label="Flux">
				<Select
					options={flowsOptions}
					value={container.flow}
					onChange={(value: unknown) =>
						setContainer({ ...container, flow: value as Option })
					}
				></Select>
			</Label>

			<Label label="Statut">
				<StatusSelect
					value={container.statut}
					onChange={(value: unknown) =>
						setContainer({ ...container, statut: value as Option })
					}
				/>
			</Label>

			{hasDP && (
				<Label label="PAV">
					<DepositSelect
						value={container.deposit}
						onChange={(deposit: Option) => {
							setContainer({ ...container, deposit });
						}}
					></DepositSelect>
				</Label>
			)}

			{tags && (
				<TagsCheckboxGroup
					value={container.tags}
					onChange={(tags) => setContainer({ ...container, tags })}
				/>
			)}

			<p>
				{!!onCancel && (
					<Button
						type="button"
						label="Annuler"
						classModifiers={['half-width', 'white']}
						onClick={onCancel}
					></Button>
				)}

				<NextButton
					label="Sauvegarder"
					disabled={isFormInvalid || saving}
					next={goNext}
					classModifiers={[!onCancel ? 'full-width' : 'half-width']}
				/>
			</p>
		</form>
	);
};
