// scss
import './Search.scss';
//components
import { InputText } from '../../lib/Forms/InputText/InputText';
import FabButton from '../../components/FabButton/FabButton';
import Header from '../../components/Header/Header';
import Loading from '../../components/FabButton/Loading/Loading';
import QRCodeReader from '../../components/QRCodeReader/QRCodeReader.js';
import SensorActiveContractModal from '../../components/SensorActiveContractModal/SensorActiveContractModal';
import SearchIcon from '../../svg/search';
import QRCode from '../../svg/qr-code';
import { Cover } from '../../components/Animation/Animation';
//context
import SensorContext from '../../components/Context/Context';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
	Organization,
	Sensor,
	SensorContextType
} from '../../components/Context/ContextType';
//router
import { useHistory } from 'react-router';
//api
import { getOrganization, getSensor } from '../../api';
import Modal from '../../components/Modal/Modal';
import {PreviousSearchedIds} from "./previousSearchedIds";
import {useSearchHistory} from "./useSearchHistory";
import {SensorCard} from "./sensorCard";

export const needToAskForConfirmationDifferentOrganisation = (userOrganization: string, sensor: Sensor): boolean => {
	/**
	 * Vérification de l'égalité de l'organisation du l'installateur, si définie, et celle du capteur
	 * sélectionné. Si c'est le même, nous débutons le wizard d'installation. Si non, nous affichons
	 * une modale de confirmation permettant de modifier l'organisation du capteur.
	 */
	const hasDifferentDirectOrganisation = (userOrganisationId: string, organisation: Organization ): boolean => {
		return  !!userOrganisationId && userOrganisationId !== organisation['@id'];
	}

	return (hasDifferentDirectOrganisation(userOrganization,  sensor.organizationObject) && !sensor.organizationObject.parent)
		|| (hasDifferentDirectOrganisation(userOrganization, sensor.organizationObject) && hasDifferentDirectOrganisation(userOrganization, sensor.organizationObject.parent!))
}

export default function Search() {
	const history = useHistory();
	const [pending, setPending] = useState(false);
	const [sensorWithContract, setSensorWithContract] = useState<Sensor | null>(
		null
	);
	const [
		confirmationDifferentOrganisationModal,
		setConfirmationDifferentOrganisationModal
	] = useState<boolean>(false);
	const [results, setResultList] = useState<Sensor[]>([]);
	const [error, setError] = useState(false);
	const sensorContext: SensorContextType = useContext(SensorContext);
	const [cameraMode, setCameraMode] = useState(false);
	const [value, setValue] = useState('');
	const [userOrganisation, setOrganisation] = useState<
		undefined | Organization
	>();
	const { previousIds, updateHistory } = useSearchHistory();
	const startSensorInstallation = () => {
		history.push('/wizard/start_sensor');
	};

	/**
	 * Si la modale de confirmation de modification de l'organisation d'un capteur est affichée
	 * (state confirmationDifferentOrganisationModal à true), nous faisons un appel réseau pour
	 * récupérer l'objet complet de l'organisation de l'installeur, afin de pouvoir afficher les
	 * propriétés suivantes dans l'interface graphique.
	 *
	 * * name
	 */
	useEffect(() => {
		if (confirmationDifferentOrganisationModal) {
			const userOrganization = sensorContext.getOrganization();
			getOrganization(userOrganization).then((organisation) =>
				setOrganisation(organisation)
			);
		}
	}, [confirmationDifferentOrganisationModal, sensorContext]);

	const onSensorClick = (sensor: Sensor) => {
		sensorContext.setSensor(sensor);
		const userOrganization = sensorContext.getOrganization();

		if (needToAskForConfirmationDifferentOrganisation(userOrganization, sensor)) {
			setConfirmationDifferentOrganisationModal(true);
			return;
		}
		console.log('[Search.tsx::onSensorClick] (line #93)]', sensor);
		onSensorValid(sensor);
	};

	/**
	 * Vérification de l'existence ou pas de contract actif pour le capteur sélectionné.
	 * Si c'est le cas, nous affichons une modale de confirmation. Si non, nous débuttons le
	 * wizard d'installation.
	 *
	 * @param sensor Le capteur sélectionné
	 */
	const onSensorValid = (sensor: Sensor) => {
		if (sensor.activeContract) {
			setSensorWithContract(sensor);
		} else {
			startSensorInstallation();
		}
	};

	const onFabButtonClick = () => {
		setResultList([]);
		setCameraMode(!cameraMode);
	};

	const onKeyDown = (e: ChangeEvent<HTMLInputElement>) => {
		// @ts-ignore
		onSearch(e.target.value).then((list: Sensor[]) => {
			if (
				list.length > 0 &&
				(e.nativeEvent as KeyboardEvent).code === 'Enter'
			) {
				onSensorClick(list[0]);
			}
		});
	};

	/**
	 * Quand nous confirmons le changement d'organisation du
	 * capteur sélectionné, suite au click du bouton Oui de la
	 * modale de confirmation, nous surchargons les propriétés
	 * organizationObject et organization du capteur sauvegardé
	 * dans le contexte React de l'application.
	 */
	const onOrganisationConfirmationModal = () => {
		if (!userOrganisation) {
			return;
		}
		setConfirmationDifferentOrganisationModal(false);
		const sensor: Sensor = {
			...sensorContext.sensor,
			organizationObject: userOrganisation as any,
			organization: sensorContext.getOrganization(),
			updateSensorOrganisation: {
				organzationId: sensorContext.getOrganization(),
				sensorId: sensorContext.sensor['@id']
			}
		};
		sensorContext.setSensor(sensor);
		onSensorValid(sensor);
	};

	const onSearch = (value: string): Promise<Sensor[]> | null => {
		if (
			cameraMode &&
			((results.length > 0 && value === results[0].serialNumber) || pending)
		) {
			return null;
		}

		setValue(value);
		setError(false);

		return new Promise((resolve) => {
			if (value.length >= 5) {

				setPending(true);
				setResultList([]);

				return getSensor(value)
					.then((sensor) => {
						if (sensor.serialNumber === value) {
							updateHistory(sensor.serialNumber)
							setResultList([sensor]);
							setPending(false);
							setError(false);
							resolve([sensor]);
						}
					})
					.catch(() => {
						setError(true);
						setPending(false);
					});
			} else {
				resolve([]);
			}
		});
	};

	const qrCodeInput = (
		<div id="SearchQRCode">
			<div
				onClick={() => {
					setResultList([]);
				}}
			>
				<QRCodeReader
					onCodeDetected={(code: string) => onSearch(code)}
					error={error}
					pending={pending}
					found={results.length > 0}
				/>
			</div>
			{results.length === 0 && (
				<div>
					<FabButton onClick={onFabButtonClick} label="Faites une recherche">
						<SearchIcon />
					</FabButton>
				</div>
			)}
			{results.length > 0 && (
				<SensorCard
					sensors={results}
					onSensorClick={onSensorClick}
					onSearch={onSearch}
				/>
			)}
		</div>
	);

	const searchInputClassModifier = error
		? ['error']
		: results.length > 0
		? ['success']
		: [];

	const searchInput = (
		<div id="Search" className="page">
			<h2>Rechercher un capteur</h2>

			<InputText
				placeholder="Numéro de série"
				type="text"
				value={value}
				onChange={(e: ChangeEvent<HTMLInputElement>) => onKeyDown(e)}
				classModifiers={searchInputClassModifier}
			/>
			<PreviousSearchedIds previousIds={previousIds} onSearch={onSearch}/>
			{!pending && !error && results.length === 0 && (
				<Cover path="/images/big/sensor_search" />
			)}
			{error && (
				<>
					<Cover path="/images/big/not_found" />
					<p className="error">Désolé, le capteur {value} est introuvable.</p>
				</>
			)}
			{pending && <Loading />}
			<SensorCard
				sensors={results}
				onSensorClick={onSensorClick}
				onSearch={onSearch}
			/>
			<FabButton onClick={onFabButtonClick} label="Scannez le QR Code">
				<QRCode />
			</FabButton>
		</div>
	);

	return (
		<>
			<Header />

			{confirmationDifferentOrganisationModal && userOrganisation?.name && (
				<Modal>
					<h3>Attention</h3>
					<p>
						Vous êtes connecté en tant que l’organisation{' '}
						{userOrganisation.name} et le capteur{' '}
						{sensorContext.sensor.serialNumber} appartient à une organisation
						différente. Souhaitez-vous faire basculer ce capteur dans
						l’organisation {userOrganisation.name}
					</p>
					<p className="flex-justify-around">
						<button
							type="button"
							className="btn btn--small btn--secondary"
							onClick={() => {
								setConfirmationDifferentOrganisationModal(false);
								setResultList([]);
								setValue('');
							}}
						>
							<span className="btn__label">Non</span>
						</button>
						<button type="button" className="btn btn--small">
							<span
								className="btn__label btn__label--white"
								onClick={() => onOrganisationConfirmationModal()}
							>
								Oui
							</span>
						</button>
					</p>
				</Modal>
			)}
			{sensorWithContract && (
				<SensorActiveContractModal
					sensor={sensorWithContract}
					ok={() => {
						setSensorWithContract(null);
						startSensorInstallation();
					}}
					cancel={() => setSensorWithContract(null)}
				/>
			)}

			{cameraMode && qrCodeInput}
			{!cameraMode && searchInput}
		</>
	);
}
