export default function Search() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path
				fillRule="evenodd"
				d="M21.4844229,19.1642013 L17.621602,15.2936996 C20.2378419,11.3690359 19.1841941,6.06199063 15.2673188,3.44054858 C11.3504434,0.819106531 6.05392976,1.8748494 3.43768987,5.79951312 C0.82144997,9.72417684 1.87509776,15.0312221 5.79197313,17.6526642 C8.66102197,19.5733329 12.4015964,19.5724997 15.2698136,17.6509976 L19.1326344,21.5206661 C19.7871102,22.159778 20.8299471,22.159778 21.4844229,21.5206661 C22.1438883,20.8807209 22.1796475,19.8441431 21.5401406,19.1833663 C21.5276665,19.1700341 21.5151924,19.1575352 21.4844229,19.1642013 Z M10.5371304,4.51045691 C13.8668903,4.51045691 16.566291,7.21522515 16.566291,10.5516059 C16.566291,13.8879867 13.8668903,16.592755 10.5371304,16.592755 C7.20737056,16.592755 4.50796982,13.8879867 4.50796982,10.5516059 C4.51129626,7.21689168 7.20903377,4.51378996 10.5371304,4.51045691 Z"
			/>
		</svg>
	);
}
