import { Button, ButtonProps } from '../../lib/Buttons/Button';

type NextButtonProps = {
	next?: () => void;
};
export default function NextButton({
	next = () => {},
	label = 'Suivant',
	type = 'button',
	...rest
}: Readonly<NextButtonProps> & Partial<Omit<ButtonProps, 'onClick'>>) {
	return <Button {...rest} type={type} label={label} onClick={next} />;
}
