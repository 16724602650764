import { genericSelectStyles } from '../../../../../components/select';
import { Option } from '../../../../../api/model';
import React, { useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import {
	createPav,
	fetchAllDepositPoints,
	generateDepositPointsUrl
} from '../../../../../api/depositPoints';

import { useOriginCreateContainerContext } from '../OriginCreateContainerContext';
import { convertDepositPointToOption } from './convertDepositPointToOption';
import { ApiGetDepositPoint } from '../../../../../api/model/container';

type DepositSelectTypes = {
	onChange: (value: Option) => void;
	value?: Option;
};
export const DepositSelect = ({
	onChange,
	value
}: Readonly<DepositSelectTypes>) => {
	const [key, setKey] = useState('');
	const { organizationId } = useOriginCreateContainerContext();

	const loadOptions = (search: string) => {
		return fetchAllDepositPoints(
			organizationId,
			`${generateDepositPointsUrl(organizationId)}?name=${search}`
		).then((depositPoints) => {
			return depositPoints.map(convertDepositPointToOption);
		});
	};

	const onCreateOptionHandler = async (name: string) => {
		const depositPoint: ApiGetDepositPoint = await createPav(
			name,
			organizationId
		).then((response) => response.json());

		setKey(depositPoint['@id']);

		onChange(convertDepositPointToOption(depositPoint)!);
	};
	return (
		<AsyncCreatableSelect
			key={key}
			styles={genericSelectStyles}
			defaultOptions
			cacheOptions
			placeholder="PAV"
			loadOptions={loadOptions}
			onCreateOption={onCreateOptionHandler}
			value={value}
			onChange={(value: unknown) => onChange(value as Option)}
		></AsyncCreatableSelect>
	);
};
