import { ApiGetDepositPoint } from '../../../../../api/model/container';
import { Option } from '../../../../../api/model';

export const convertDepositPointToOption = (
	depositPoint?: ApiGetDepositPoint
): Option | undefined => {
	if (!depositPoint) {
		return;
	}
	return {
		value: depositPoint['@id'],
		label: depositPoint.name
	};
};
