import {Sensor, SensorMeasure} from "../../../components/Context/ContextType";
import {Measure} from "../measure";
import {SignalStrength} from "../../../components/SignalStrength";

type SensorCardLastMeasureItemProps = {
    measure: SensorMeasure | undefined;
    term: string;
    placeholder: string;
}
const SensorCardLastMeasureItem = ({ measure, term, placeholder }: SensorCardLastMeasureItemProps) => {
    if(!measure){
        return null;
    }

    return (
        <>
            <dt>{term}</dt>
            <dl><SignalStrength signalStrength={measure.signalStrength} rssi={measure.rssi} snr={measure.snr}/></dl>
            <Measure
                measure={measure}
                messageIfUndefined={placeholder}
            ></Measure>
        </>
    )
}

export const SensorCardLastMeasure = ({ sensor }: { sensor: Sensor }) => {
    return (
        <dl>
            <SensorCardLastMeasureItem measure={sensor.monitor?.lastManualMeasure} term="Dernière mesure manuelle:" placeholder="Aucune mesure manuelle"/>

            <SensorCardLastMeasureItem measure={sensor.monitor?.lastPeriodicalMeasure} term="Dernière mesure périodique:" placeholder="Aucune mesure périodique"/>

            <SensorCardLastMeasureItem measure={sensor.monitor?.lastFirstUplink} term="Dernier 'First Uplink':" placeholder="Aucun First Uplink"/>
        </dl>
    );
};
