export function Loader() {
	return (
		<svg
			id="loader"
			xmlns="http://www.w3.org/2000/svg"
			width="19"
			height="19"
			viewBox="0 0 19 19"
		>
			<g id="Groupe_609" data-name="Groupe 609" transform="translate(8.943)">
				<g id="Groupe_608" data-name="Groupe 608">
					<path
						id="Tracé_540"
						data-name="Tracé 540"
						d="M241.558,0A.557.557,0,0,0,241,.557V4.134a.557.557,0,0,0,1.113,0V.557A.557.557,0,0,0,241.558,0Z"
						transform="translate(-241.001)"
					/>
				</g>
			</g>
			<g
				id="Groupe_611"
				data-name="Groupe 611"
				transform="translate(8.943 14.309)"
			>
				<g id="Groupe_610" data-name="Groupe 610">
					<path
						id="Tracé_541"
						data-name="Tracé 541"
						d="M241.558,385.6a.557.557,0,0,0-.557.557v3.577a.557.557,0,0,0,1.113,0v-3.577A.557.557,0,0,0,241.558,385.6Z"
						transform="translate(-241.001 -385.601)"
					/>
				</g>
			</g>
			<g
				id="Groupe_613"
				data-name="Groupe 613"
				transform="translate(4.472 1.198)"
			>
				<g id="Groupe_612" data-name="Groupe 612">
					<path
						id="Tracé_542"
						data-name="Tracé 542"
						d="M123.326,35.661l-1.789-3.1a.557.557,0,1,0-.964.557l1.789,3.1a.557.557,0,1,0,.964-.557Z"
						transform="translate(-120.499 -32.284)"
					/>
				</g>
			</g>
			<g
				id="Groupe_615"
				data-name="Groupe 615"
				transform="translate(11.626 13.59)"
			>
				<g id="Groupe_614" data-name="Groupe 614">
					<path
						id="Tracé_543"
						data-name="Tracé 543"
						d="M316.127,369.6l-1.789-3.1a.557.557,0,0,0-.964.557l1.789,3.1a.557.557,0,1,0,.964-.557Z"
						transform="translate(-313.3 -366.226)"
					/>
				</g>
			</g>
			<g
				id="Groupe_617"
				data-name="Groupe 617"
				transform="translate(1.198 4.472)"
			>
				<g id="Groupe_616" data-name="Groupe 616">
					<path
						id="Tracé_544"
						data-name="Tracé 544"
						d="M36.219,122.36l-3.1-1.789a.557.557,0,1,0-.557.964l3.1,1.789a.557.557,0,0,0,.557-.964Z"
						transform="translate(-32.286 -120.497)"
					/>
				</g>
			</g>
			<g
				id="Groupe_619"
				data-name="Groupe 619"
				transform="translate(13.59 11.626)"
			>
				<g id="Groupe_618" data-name="Groupe 618">
					<path
						id="Tracé_545"
						data-name="Tracé 545"
						d="M370.16,315.162l-3.1-1.789a.557.557,0,0,0-.557.964l3.1,1.789a.557.557,0,0,0,.557-.964Z"
						transform="translate(-366.227 -313.299)"
					/>
				</g>
			</g>
			<g id="Groupe_621" data-name="Groupe 621" transform="translate(0 8.943)">
				<g id="Groupe_620" data-name="Groupe 620">
					<path
						id="Tracé_546"
						data-name="Tracé 546"
						d="M4.134,241H.557a.557.557,0,1,0,0,1.113H4.134a.557.557,0,1,0,0-1.113Z"
						transform="translate(0 -241.002)"
					/>
				</g>
			</g>
			<g
				id="Groupe_623"
				data-name="Groupe 623"
				transform="translate(14.309 8.943)"
			>
				<g id="Groupe_622" data-name="Groupe 622">
					<path
						id="Tracé_547"
						data-name="Tracé 547"
						d="M389.736,241h-3.577a.557.557,0,1,0,0,1.113h3.577a.557.557,0,1,0,0-1.113Z"
						transform="translate(-385.602 -241.002)"
					/>
				</g>
			</g>
			<g
				id="Groupe_625"
				data-name="Groupe 625"
				transform="translate(1.198 11.626)"
			>
				<g id="Groupe_624" data-name="Groupe 624">
					<path
						id="Tracé_548"
						data-name="Tracé 548"
						d="M36.424,313.578a.556.556,0,0,0-.76-.2l-3.1,1.789a.557.557,0,1,0,.557.964l3.1-1.789A.557.557,0,0,0,36.424,313.578Z"
						transform="translate(-32.287 -313.299)"
					/>
				</g>
			</g>
			<g
				id="Groupe_627"
				data-name="Groupe 627"
				transform="translate(13.59 4.472)"
			>
				<g id="Groupe_626" data-name="Groupe 626">
					<path
						id="Tracé_549"
						data-name="Tracé 549"
						d="M370.363,120.779a.557.557,0,0,0-.76-.2l-3.1,1.789a.557.557,0,1,0,.557.964l3.1-1.789A.557.557,0,0,0,370.363,120.779Z"
						transform="translate(-366.226 -120.501)"
					/>
				</g>
			</g>
			<g
				id="Groupe_629"
				data-name="Groupe 629"
				transform="translate(4.472 13.59)"
			>
				<g id="Groupe_628" data-name="Groupe 628">
					<path
						id="Tracé_550"
						data-name="Tracé 550"
						d="M123.123,366.3a.556.556,0,0,0-.76.2l-1.789,3.1a.557.557,0,1,0,.964.557l1.789-3.1A.557.557,0,0,0,123.123,366.3Z"
						transform="translate(-120.499 -366.226)"
					/>
				</g>
			</g>
			<g
				id="Groupe_631"
				data-name="Groupe 631"
				transform="translate(11.626 1.198)"
			>
				<g id="Groupe_630" data-name="Groupe 630">
					<path
						id="Tracé_551"
						data-name="Tracé 551"
						d="M315.923,32.362a.557.557,0,0,0-.76.2l-1.789,3.1a.557.557,0,1,0,.964.557l1.789-3.1A.557.557,0,0,0,315.923,32.362Z"
						transform="translate(-313.299 -32.287)"
					/>
				</g>
			</g>
		</svg>
	);
}
