import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import {
	commonHeaders,
	paramsObjToUrl,
	pollData,
	returnResponseOrThrowError
} from './utils';
import { fetchAndTrack } from './tracker';
import { Coordinate } from './model';
import { fetchWithAuthentication } from './fetchWithAuthentication';
import { authorize } from './authentication';
import { ApiGetContainer, ApiPostContainer } from './model/container';

const host = process.env.REACT_APP_DATA_API;

dayjs.extend(isToday);

const mockApiResponse = process.env.REACT_APP_MOCK_PRIVATEAPI === 'true';
console.log('API Is mocked ? ', mockApiResponse);

const END_CONTAINER_ACTIVE_CONTRACT = 'END_CONTAINER_ACTIVE_CONTRACT';
const CREATE_CONTRACT = 'CREATE_CONTRACT';
const CREATE_CONTAINER = 'CREATE_CONTAINER';
const UPDATE_CONTAINER = 'UPDATE_CONTAINER';
const TAG_CONTAINER = 'TAG_CONTAINER';
const DELETE_TAG_CONTAINER = 'DELETE_TAG_CONTAINER';
const TAG_SENSOR = 'TAG_SENSOR';
const UPDATE_SENSOR_ORGANIZATION = 'UPDATE_SENSOR_ORGANIZATION';

export async function getUserHasAccessToMultipleOrgs() {
	return fetchWithAuthentication(`${host}/v2/organizations`).then((data) => {
		const parentOrganizationSize = data?.['hydra:totalItems'];
		if (parentOrganizationSize > 1) {
			return true;
		}
		return data?.['hydra:member']?.[0]?.children?.length > 0;
	});
}

export async function sendUserLogs(log: unknown) {
	return fetch(`${host}/export/install_reports`, {
		method: 'POST',
		headers: {
			...commonHeaders(),
			'content-type': 'application/ld+json'
		},
		body: JSON.stringify(log)
	});
}

export async function getUserLogs() {
	const userId = await authorize();
	if (!userId) {
		return Promise.reject('Failed fetching user information');
	}

	return fetchWithAuthentication(
		`${host}/export/users/${userId}/install_reports`
	);
}

export async function exportUserLogs(body: any) {
	const userId = await authorize();
	if (!userId) {
		return Promise.reject('Failed fetching user information');
	}

	return fetch(`${host}/export/users/${userId}/export`, {
		method: 'POST',
		headers: {
			...commonHeaders(),
			'content-type': 'application/ld+json'
		},
		body: JSON.stringify(body)
	});
}

export async function exportOrganizationLogs(
	organizationId: string,
	body: any
) {
	const userId = await authorize();
	if (!userId) {
		return Promise.reject('Failed fetching user information');
	}

	return fetch(`${host}/export/organizations/${organizationId}/export`, {
		method: 'POST',
		headers: {
			...commonHeaders(),
			'content-type': 'application/ld+json'
		},
		body: JSON.stringify(body)
	});
}

export function getSensorContract(serialNumber: string) {
	return fetchWithAuthentication(
		`${host}/v2/sensors/${serialNumber}/contracts`
	).then((data) => {
		return data['hydra:member'].find((contract: any) => contract.active);
	});
}

export function getSensorActiveContract(activeSensorIri: string) {
	return new Promise((resolve, reject) => {
		if (!activeSensorIri) {
			resolve(null);
		}
		fetchWithAuthentication(host + activeSensorIri)
			.then((data) => {
				resolve(data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

type ContainerListParamType = {
	with: string;
	orderByProximity?: string;
	itemsPerPage: `${number}`;
	search: string;
	logisticStatus?: string;
};

export function getContainerList(
	signal: AbortSignal,
	organisationId: string,
	coords: Coordinate,
	search = '',
	logisticStatus: string,
	tags: string[]
): Promise<ApiGetContainer[]> {
	const params: ContainerListParamType = {
		with: 'contracts,properties,model,tags,depositPoint,group',
		itemsPerPage: '100',
		search
	};

	if (!!logisticStatus) {
		params.logisticStatus = logisticStatus;
	}
	if (!!coords.latitude) {
		params.orderByProximity = `${coords.latitude},${coords.longitude}`;
	}

	let paramsUrl = paramsObjToUrl(params as any);
	if (tags.length > 0) {
		paramsUrl = tags.reduce((acc, tag) => {
			return `${acc}&tags[]=${tag}`;
		}, paramsUrl);
	}

	return fetchWithAuthentication(
		`${host}/v2/organizations/${organisationId}/container_views${paramsUrl}`,
		{
			signal
		}
	).then((data) => {
		return data?.['hydra:member'];
	});
}

export function getOrganizations(url: string) {
	return fetchWithAuthentication(host + url).then((data) => {
		return {
			organizations: data['hydra:member'],
			next: data['hydra:view'] ? data['hydra:view']['hydra:next'] : null,
			totalItem: data['hydra:totalItems']
		};
	});
}

export function getGroupList(
	organisationId: string,
	url = `/v2/organizations/${organisationId}/groups`
) {
	return fetchWithAuthentication(host + url).then((data) => {
		if (!data) {
			return {};
		}
		return {
			groupList: data['hydra:member'],
			next: data['hydra:view'] ? data['hydra:view']['hydra:next'] : null,
			totalItem: data['hydra:totalItems']
		};
	});
}

export function getContainerModelList(organisationId: string, url: string) {
	return fetchWithAuthentication(host + url).then((data) => {
		return {
			containerModelList: data['hydra:member'],
			next: data['hydra:view'] ? data['hydra:view']['hydra:next'] : null,
			totalItem: data['hydra:totalItems']
		};
	});
}

export function createContainer(groupId: string, container: ApiPostContainer) {
	return fetchAndTrack(
		`${host}/v2/groups/${groupId}/containers`,
		{
			method: 'POST',
			headers: {
				...commonHeaders(),
				'content-type': 'application/ld+json'
			},
			body: JSON.stringify(container)
		},
		CREATE_CONTAINER
	);
}

export function tagSensor(sensorId: string, tagId: string) {
	return fetchAndTrack(
		`${host}/v2/sensors/${sensorId}/tags/${tagId}`,
		{
			method: 'PUT',
			headers: {
				...commonHeaders()
			}
		},
		TAG_SENSOR
	);
}

export function updateSensorOrganisation(
	organisationId: string,
	sensorId: string
) {
	return fetchAndTrack(
		`${host}/batch/sensors/update_organization`,
		{
			method: 'POST',
			headers: {
				...commonHeaders()
			},
			body: JSON.stringify({
				organization: organisationId,
				sensors: [sensorId]
			})
		},
		UPDATE_SENSOR_ORGANIZATION
	);
}

export function tagContainer(containerId: string, tagId: string) {
	return fetchAndTrack(
		`${host}/v2/containers/${containerId}/tags/${tagId}`,
		{
			method: 'PUT',
			headers: {
				...commonHeaders()
			}
		},
		TAG_CONTAINER
	);
}

export function removeTagContainer(containerId: string, tagId: string) {
	return fetchAndTrack(
		`${host}/v2/containers/${containerId}/tags/${tagId}`,
		{
			method: 'DELETE',
			headers: {
				...commonHeaders()
			}
		},
		DELETE_TAG_CONTAINER
	);
}

export function getOrganization(organization: string) {
	if (!organization) {
		return Promise.resolve({});
	}
	return fetchWithAuthentication(`${host}${organization}?with=tags`);
}

export function getSensor(sensorId: string) {
	return fetchWithAuthentication(
		`${host}/v2/sensors/${sensorId}?with=monitor,tags`
	).then((sensor) => {
		const activeContractIRI = sensor.activeContract;
		const organization = fetchWithAuthentication(host + sensor.organization);
		const sensorActiveContract = activeContractIRI
			? getSensorActiveContract(activeContractIRI)
			: null;
		return Promise.all([organization, sensorActiveContract]).then(
			([organization, contract]) => {
				return {
					...sensor,
					contract,
					organizationObject: organization
				};
			}
		);
	});
}

export function getProviderAndStrength(serialNumber: string) {
	return new Promise((resolve, reject) => {
		fetchWithAuthentication(
			`${host}/v2/sensors/${serialNumber}/sensor_monitor`
		).then((data) => {
			if (mockApiResponse) {
				return resolve({
					provider: 'LIVE_OBJECT',
					network: 'LORA',
					signalStrength: 85,
					lastSignalDate: new Date().toISOString(),
					snr: '10',
					rssi: '20 dBm'
				});
			}

			if (!data) {
				reject('error.no_first_uplink_today');
				return;
			}

			if (data['lastFirstUplink']) {
				if (dayjs(new Date(data.lastFirstUplink.date)).isToday()) {
					return resolve({
						provider: data.lastFirstUplink.provider,
						network: data.lastFirstUplink.network,
						signalStrength: data.lastFirstUplink.signalStrength,
						lastSignalDate: data.lastFirstUplink.date,
						snr: `${data.lastFirstUplink.snr}`,
						rssi: `${data.lastFirstUplink.rssi} dBm`
					});
				}
			}

			reject('error.no_first_uplink_today');
		});
	});
}

export function getMeasure(serialNumber: string) {
	if (mockApiResponse) {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve({
					measureName: 'Mesure',
					measureValue: 85,
					measureDate: '2022-01-01'
				});
			}, 1000);
		});
	}

	return pollData((resolve) => {
		fetchWithAuthentication(
			`${host}/v2/sensors/${serialNumber}/sensor_monitor`
		).then((response) => {
			if (mockApiResponse) {
				resolve({
					measureName: 'Mesure',
					measureDate: new Date(),
					measureValue: 1000
				});
			}

			if (response?.lastManualMeasure) {
				const lastManualMeasure = response.lastManualMeasure;
				const diff =
					(new Date().getTime() - new Date(lastManualMeasure.date).getTime()) /
					60000;
				if (diff < parseInt(process.env.REACT_APP_MEASURE_TTL_LIMIT)) {
					resolve({
						measureName: 'Mesure',
						measureDate: new Date(lastManualMeasure.date),
						measureValue: lastManualMeasure.value
					});
				}
			}
		});
	}, 'error.no_manual_measure');
}

export function updateContainer(containerId: string, override: any) {
	return fetchAndTrack(
		`${host}/v2/containers/${containerId}`,
		{
			method: 'PATCH',
			headers: {
				...commonHeaders(),
				'content-type': 'application/merge-patch+json'
			},
			body: JSON.stringify(override)
		},
		UPDATE_CONTAINER
	).then(async (response) => {
		return returnResponseOrThrowError(response);
	});
}

export function createContract(containerId: string, sensor: any) {
	return fetchAndTrack(
		`${host}/v2/containers/${containerId}/contracts`,
		{
			method: 'POST',
			headers: {
				...commonHeaders(),
				'content-type': 'application/ld+json'
			},
			body: JSON.stringify({ sensor })
		},
		CREATE_CONTRACT
	).then(async (response) => {
		return returnResponseOrThrowError(response);
	});
}

export function updateSensorAndCreateContract(sensor: any) {
	let patchBody: any = {
		picture: sensor.container.picture,
		addressComment: sensor.container.addressComment,
		note: sensor.container.note
	};

	if (!sensor.keepSensorCoordinates) {
		patchBody = {
			...patchBody,
			latitude: sensor.coords.lat,
			longitude: sensor.coords.lng
		};
	}

	/**
	 * Si nous avons modifier les mesures du container, nous les modifications également via l'API
	 */
	if (sensor.updateContainerMeasure) {
		patchBody.properties = {
			CONTAINER_FULL_MEASURE: parseInt(sensor.updateContainerMeasure.full),
			CONTAINER_EMPTY_MEASURE: parseInt(sensor.updateContainerMeasure.empty)
		};
	}

	return updateContainer(sensor.container.id, patchBody).then(() =>
		createContract(sensor.container.id, sensor['@id'])
	);
}

export function getPlacesFromAzure(query: string) {
	return new Promise((resolve) => {
		setTimeout(() => {
			const params = {
				'api-version': '1.0',
				'subscription-key': process.env.REACT_APP_AZURE_MAP_API_KEY!,
				language: 'en-US',
				query
			};
			fetchAndTrack(
				`https://atlas.microsoft.com/search/fuzzy/json${paramsObjToUrl(
					params
				)}`,
				{ method: 'GET' }
			)
				.then((response) => response.json())
				.then((data) => resolve(data.results));
		}, 0);
	});
}

export function endContainerActiveContractByIri(
	activeContractIri: string,
	override: unknown
) {
	return new Promise((resolve) => {
		fetchAndTrack(
			`${host}${activeContractIri}`,
			{
				method: 'PATCH',
				headers: {
					...commonHeaders(),
					'content-type': 'application/merge-patch+json'
				},
				body: JSON.stringify(override)
			},
			END_CONTAINER_ACTIVE_CONTRACT
		).then((response) => {
			resolve(returnResponseOrThrowError(response));
		});
	});
}
