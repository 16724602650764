import {Sensor} from "../../../components/Context/ContextType";

export const Information = ({ sensor }: { sensor: Sensor}) => {
    if(sensor.tags?.includes("/v2/tags/sensor-button-control")){
        return (
            <p>
                Faites deux appuis longs sur le bouton d'action du capteur pour
                qu'il sélectionne automatiquement un réseau. Puis veuillez cliquer
                sur suivant.
            </p>
        )
    }
    return (
        <p>
            Déposez votre aimant sur le pictogramme proche de la lentille pendant 3 secondes pour déclencher la sélection de réseau. Puis cliquez sur suivant.
        </p>
    )

}
