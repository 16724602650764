export function vh(v: number) {
	var h = Math.max(
		document.documentElement.clientHeight,
		window.innerHeight || 0
	);
	return Math.round((v * h) / 100);
}

export const getImageSizes = (
	uri: string
): Promise<{
	originalWidth: number;
	originalHeight: number;
	image: HTMLImageElement;
}> => {
	return new Promise((resolve, reject) => {
		let img = new Image();
		img.onload = () =>
			resolve({
				originalWidth: img.width,
				originalHeight: img.height,
				image: img
			});
		img.onerror = reject;
		img.src = uri;
	});
};

// http://stackoverflow.com/questions/1240408/reading-bytes-from-a-javascript-string
const stringToBytesFaster = (str: string) => {
	let ch,
		st,
		re = [],
		j = 0;
	for (let i = 0; i < str.length; i++) {
		ch = str.charCodeAt(i);
		if (ch < 127) {
			re[j++] = ch & 0xff;
		} else {
			st = []; // clear stack
			do {
				st.push(ch & 0xff); // push byte to stack
				ch = ch >> 8; // shift value down by 1 byte
			} while (ch);
			// add stack contents to result
			// done because chars have "wrong" endianness
			st = st.reverse();
			for (let k = 0; k < st.length; ++k) re[j++] = st[k];
		}
	}
	// return an array of bytes
	return re;
};

export const resizeReactCameraPicture = async (
	uri: string,
	maxDimension: number = 1920
) => {
	let canvas = document.createElement('canvas'),
		ctx = canvas.getContext('2d');
	let imgQuality = 1.0;

	const { originalWidth, originalHeight, image } = await getImageSizes(uri);

	const sizeOfPictureInMb = stringToBytesFaster(uri).length / 1000000;
	if (sizeOfPictureInMb > 0.5) {
		imgQuality = 0.8;
	}

	// set its dimension to target size
	// @ts-ignore
	canvas.width =
		originalWidth > originalHeight
			? maxDimension
			: maxDimension * (originalWidth / originalHeight);
	// @ts-ignore
	canvas.height =
		originalHeight > originalWidth
			? maxDimension
			: maxDimension * (originalWidth / originalHeight);

	// draw source image into the off-screen canvas:
	// @ts-ignore
	ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

	// encode image to data-uri with base64 version of compressed image
	return canvas.toDataURL('image/jpeg', imgQuality);
};

export function dataURItoBlob(dataURI: string, name?: string): Blob {
	const byteString = atob(dataURI.split(',')[1]);

	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	const ab = new ArrayBuffer(byteString.length);

	const ia = new Uint8Array(ab);

	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ab], { type: mimeString });
}

export function toBuffer(ab: ArrayBuffer): Buffer {
	const buf = Buffer.alloc(ab.byteLength);
	const view = new Uint8Array(ab);
	for (let i = 0; i < buf.length; ++i) {
		buf[i] = view[i];
	}
	return buf;
}
