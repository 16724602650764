import { Option } from '../../../../../api/model';
import { getFlowLabel } from './getFlowLabel';

export const convertFlowToOption = (flow: any): Option | undefined => {
	if (!flow) {
		return;
	}
	return {
		value: flow['@id'],
		label: getFlowLabel(flow.key)
	};
};
