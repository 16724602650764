import './CommentModal.scss';
import Modal from '../Modal/Modal';
import { Loader } from '../../svg/loader';

type CommentModalProps = {
	children: any;
	title: string;
	instructions: string;
	ok: any;
	cancel: any;
	passAction?: any;
	pass?: boolean;
	loader?: boolean;
};

export default function CommentModal({
	children,
	title,
	instructions,
	ok,
	cancel,
	pass,
	passAction,
	loader
}: CommentModalProps) {
	return (
		<div id="commentModal" className="comment-modal">
			<Modal>
				<div className="comment-modal__header">
					<h1 className="comment-modal__title">{title}</h1>
					{pass && (
						<button
							type="button"
							className="comment-modal__button comment-modal__button-pass"
							onClick={passAction}
						>
							Passer
						</button>
					)}
				</div>
				<p className="comment-modal__instructions">{instructions}</p>
				<div>{children}</div>
				<div className="comment-modal__actions">
					<button
						type="button"
						className="comment-modal__button comment-modal__button-cancel"
						onClick={cancel}
					>
						Annuler
					</button>
					<button
						type="button"
						className="btn comment-modal__button comment-modal__button-ok"
						onClick={ok}
					>
						{loader && <Loader />}
						<span className="btn__label">Ajouter</span>
					</button>
				</div>
			</Modal>
		</div>
	);
}
