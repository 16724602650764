export const createIcon = (imageIri, anchor, options = null) => {
	return window.L.icon({
		iconUrl: imageIri,
		iconAnchor: anchor,
		...options
	});
};

export const createMarker = (latlng, icon) => {
	return window.L.marker(latlng, { icon: icon });
};

export const setMarkers = (markers, map) => {
	return window.L.featureGroup(markers).addTo(map);
};

export const initMap = () => {
	if(!document.querySelector("#map")){
		return;
	}
	const map = window.L.map('map', {
		zoom: 18
	});
	window.L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
		maxZoom: 18,
		attribution:
			'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
		id: 'mapbox/streets-v11',
		tileSize: 512,
		zoomOffset: -1,
		zoomControl: false
	}).addTo(map);

	return map;
};

export const createMapController = (map, onClickFunction) => {
	return window.L.Control.extend({
		onAdd: function (map) {
			const container = window.L.DomUtil.create(
				'div',
				'leaflet-control-locate leaflet-bar leaflet-control'
			);
			this._container = container;
			this._map = map;
			this._layer = this.options.layer || new window.L.LayerGroup();
			this._layer.addTo(map);
			this._event = undefined;
			this._compassHeading = null;
			this._prevBounds = null;

			const link = window.L.DomUtil.create(
				'a',
				'leaflet-bar-part leaflet-bar-part-single',
				container
			);
			link.role = 'button';
			link.href = '#';
			const icon = window.L.DomUtil.create('img', 'current', link);
			icon.src = '/images/room.svg';
			this._link = link;
			this._icon = icon;

			window.L.DomEvent.on(this._link, 'click', onClickFunction, this).on(
				this._link,
				'dblclick',
				window.L.DomEvent.stopPropagation
			);

			return container;
		}
	});
};
