import { fetchWithAuthentication } from './fetchWithAuthentication';
import { commonHeaders, fetchAll, fetchOnePage } from './utils';
import { ClientSideApiPayload } from './model';
import { getCurrentPosition } from './geolocation';
import { fetchAndTrack } from './tracker';
import { ApiGetDepositPoint } from './model/container';

const CREATE_DEPOSIT_POINT = 'CREATE_DEPOSIT_POINT';

export const generateDepositPointsUrl = (organisationId: string): string => {
	return `/v2/organizations/${organisationId}/deposit_points`;
};

export const createPav = async (name: string, organisationId: string) => {
	const coords = await getCurrentPosition();

	const payload = {
		name,
		longitude: coords.coords.longitude,
		latitude: coords.coords.latitude,
		address: 'Adresse non renseignée'
	};

	return fetchAndTrack(
		process.env.REACT_APP_DATA_API + generateDepositPointsUrl(organisationId),
		{
			method: 'POST',
			headers: {
				...commonHeaders(),
				'content-type': 'application/ld+json'
			},
			body: JSON.stringify(payload)
		},
		CREATE_DEPOSIT_POINT
	);
};

/**
 * Cette méthode retourne **true** si l'organisation a au moins un deposit point.
 *
 * @param organizationId l'identifiant de l'organiation pour laquelle nous souhaitons récupérer les deposit points
 */
export const hasDepositPoints = (organizationId: string): Promise<boolean> => {
	return fetchWithAuthentication(
		process.env.REACT_APP_DATA_API + generateDepositPointsUrl(organizationId)
	).then((data) => {
		if (!data) {
			return false;
		}
		return data['hydra:totalItems'] >= 1;
	});
};

/**
 * Méthode pour récupérer tous les Deposit Points
 *
 * @param organisationId l'identifiant de l'organiation pour laquelle nous souhaitons récupérer les deposit points
 * @param url l'url finale vers la ressource de l'organisation. Elle contiendra les informations de la page souhaitée
 * @param previousDepositPoints un tableau de **deposit_points** qui sera utilisé pour agréger l'ensemble de la donnée
 */
export const fetchAllDepositPoints = fetchAll(getDepositPoints);

/**
 * Cette méthode est en charge de récupérer une page de **deposit_points** . Elle sera exécutée pour récupérer
 * plusieurs pages.
 *
 * @param organisationId l'identifiant de l'organiation pour laquelle nous souhaitons récupérer les deposit points
 * @param url l'url finale vers la ressource de l'organisation
 */
export function getDepositPoints(
	organisationId: string,
	url = generateDepositPointsUrl(organisationId)
): Promise<ClientSideApiPayload<ApiGetDepositPoint>> {
	return fetchOnePage(process.env.REACT_APP_DATA_API + url);
}
