import {ReactElement, useContext, useState, createContext} from 'react';
import {Organization, SensorContextType} from './ContextType';

const SensorContext = createContext<SensorContextType | {}>({});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _defaultSensorBackup = {
	'@context': '/v2/contexts/Sensor',
	'@id': '/v2/sensors/60054',
	'@type': 'Sensor',
	serialNumber: '60054',
	status: 'PAIRED',
	liveObjectOrangeId: '70B3D5326000EA96',
	coords: {
		lat: 50.523656700000004,
		lng: 3.1100301000000035
	},
	provider: 'Lora',
	signalStrength: 3,
	sensorConfiguration: {
		'@id': '/v2/sensor_configurations/68354398-a757-4019-9538-d77f4fceeeb0',
		'@type': 'SensorConfiguration',
		id: '68354398-a757-4019-9538-d77f4fceeeb0',
		name: 'Default',
		activePhaseStartAt: '2012-03-13T06:00:00+00:00',
		activePhasePeriod: 12,
		inactivePhaseStartAt: '2012-03-13T19:00:00+00:00',
		inactivePhasePeriod: 90,
		measurePerSending: 3,
		dailyConfigTime: '2012-03-13T06:00:00+00:00',
		distanceMode: 'LONG',
		anglePosition: 0,
		timingBudget: 200,
		roiX: 4,
		roiY: 8
	},
	sigfoxId: 'F2CFD',
	version: '1.1',
	lastSyncSuccess: true,
	organization: '/v2/organizations/d9913e0a-105c-4cbe-af45-0594653eab18',
	contracts: ['/v2/contracts/817a71cf-624c-4638-8bab-e2413cf652c2'],
	activeContract: '/v2/contracts/817a71cf-624c-4638-8bab-e2413cf652c2',
	latestContract: '/v2/contracts/817a71cf-624c-4638-8bab-e2413cf652c2',
	organizationObject: {
		'@context': '/v2/contexts/Organization',
		'@id': '/v2/organizations/d9913e0a-105c-4cbe-af45-0594653eab18',
		'@type': 'Organization',
		id: 'd9913e0a-105c-4cbe-af45-0594653eab18',
		name: 'Disney',
		groups: [
			{
				'@id': '/v2/groups/a6fcda98-85fb-4c80-b64d-790da102f2ff',
				'@type': 'Group',
				name: 'DisneyLand Paris',
				children: [
					{
						'@id': '/v2/groups/21f8ed59-d551-4dfb-ba50-1cf53cb31acb',
						'@type': 'Group',
						name: 'Futuro',
						children: []
					}
				]
			},
			{
				'@id': '/v2/groups/21f8ed59-d551-4dfb-ba50-1cf53cb31acb',
				'@type': 'Group',
				name: 'Futuro',
				children: []
			}
		],
		users: [
			'/v2/users/21570c95-b685-477b-aeaa-98ebd2561995',
			'/v2/users/5ced0ec3-917a-457f-90ee-ecaceb6251c9'
		],
		children: [],
		parent: null
	},
	outdoor: {
		measureName: 'Mesure',
		measureValue: 145
	},
	indoor: {
		measureName: 'Mesure',
		measureValue: 145
	},
	container: {
		'@id': '/v2/containers/0034be16-a792-4ee3-b1fc-1a17f158d79e',
		'@type': 'Container',
		id: '0034be16-a792-4ee3-b1fc-1a17f158d79e',
		name: 'Nala Test',
		picture:
			'https://devheyliot.blob.core.windows.net/photos/organization/0034be16-a792-4ee3-b1fc-1a17f158d79e/container/60054-1615280188898.png',
		latitude: 2.7768789,
		longitude: 48.8771917,
		addrStreet: 'Boulevard du Grand Fossé',
		addrPostalCode: '77700',
		addrCity: 'Chessy',
		addrCountry: 'France',
		group: '/v2/groups/21f8ed59-d551-4dfb-ba50-1cf53cb31acb',
		model: '/v2/container_models/900390f5-99f6-42b5-8f59-9887a2dc0aba'
	}
};

const defaultSensor: SensorContextType | {} = {};

export const useSensorContext = () => {
	const context = useContext(SensorContext);
	if(context === {}){
		throw new Error("Le context SensorContext n'existe pas. Assurez-vous d'avoir utiliser le composant SensorContextProvider")
	}

	return context as SensorContextType;
}

export function SensorContextProvider({
	children
}: {
	children: ReactElement;
}) {
	const [sensor, setSensor] = useState(defaultSensor);
	const [superInstaller, setSuperInstaller] = useState();
	const [accessToMulipleOrgs, setAccessToMulipleOrgs] = useState<boolean | undefined>(undefined);
	const [userOrganization, setUserOrganization] = useState<Organization | undefined>();
	const setOrganization = (organization: string) => {
		if (!organization) {
			return;
		}
		localStorage.setItem('user_organization', organization);
	};
	const getOrganization = () => {
		return localStorage.getItem('user_organization');
	};

	const hasAccessToMulipleOrgs = (): boolean | undefined => {
		return accessToMulipleOrgs;
	}
	function isSuperInstaller(): boolean | undefined {
		return superInstaller;
	}

	function getUserOrganizationName(): string | undefined {
		return userOrganization?.name;
	}

	function getUserOrganization(): Organization | undefined {
		return userOrganization;
	}

	return (
		<SensorContext.Provider
			value={{
				sensor,
				setSensor,
				setOrganization,
				getOrganization,
				isSuperInstaller,
				setSuperInstaller,
				hasAccessToMulipleOrgs,
				setAccessToMulipleOrgs,
				getUserOrganizationName,
				setUserOrganization,
				getUserOrganization
			}}
		>
			{children}
		</SensorContext.Provider>
	);
}

export default SensorContext;
