import { useOriginCreateContainerContext } from './ContainerForm/OriginCreateContainerContext';
import ArrowLeft from '../../../svg/ArrowLeft';
import React from 'react';
import Header from '../../../components/Header/Header';
import { useHistory } from 'react-router';

export const ContainerMenu = () => {
	const { fromWizard } = useOriginCreateContainerContext();
	const history = useHistory();

	if (fromWizard) {
		return (
			<header id="HeaderTitleWithBackButton">
				<button
					type="button"
					onClick={() => history.goBack()}
					aria-label="Précédent"
				>
					<ArrowLeft />
				</button>
				<h2>Sélectionner un contenant</h2>
			</header>
		);
	}

	return <Header></Header>;
};
