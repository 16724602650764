import './Modal.scss';

export default function Modal({ children }) {
	return (
		<div className="modal-container">
			<div className="modal" id="modal">
				<div className="content">{children}</div>
			</div>
		</div>
	);
}
