import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

export const initLogRocket = () => {
	if (process.env.REACT_APP_FEATURE_ENV !== 'local') {
		return new Promise((resolve) => {
			LogRocket.init('heyliot/heyliot-mobile-installer');
			setupLogRocketReact(LogRocket);
			LogRocket.getSessionURL((url) => Promise.resolve(url));
		});
	}

	return Promise.resolve();
};

export const identify = (email: string) => {
	if (process.env.REACT_APP_FEATURE_ENV !== 'local') {
		LogRocket.identify(email);
	}
};

export const createLogger = (prefix: string) => ({
	log(...messages: string[]) {
		if (process.env.REACT_APP_FEATURE_ENV !== 'production') {
			console.log(`[${prefix}]`, ...messages);
		}
	}
});
