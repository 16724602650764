import { Sensor } from '../components/Context/ContextType';
import { sendUserLogs } from './index';

type Track = {
	email: string | null;
	sensorId: string;
	organizationId?: string;
	startedAt: Date;
	endedAt?: Date;
	steps: TrackStep[];
	success: boolean;
};

type TrackStep = {
	name: string;
	url: string;
	body: any;
	responseStatus: number;
	responseBody: any;
	createdEntities: string[];
	updatedEntities: string[];
};

const initDefaultTracker = (sensor?: Sensor): Track => {
	return {
		email: localStorage.getItem('email'),
		sensorId: sensor?.['@id']!,
		organizationId: undefined,
		startedAt: new Date(),
		endedAt: undefined,
		steps: [],
		success: false
	};
};
const isFirstCall = (url: string): boolean => url.indexOf('?with=monitor') >= 0;

const getOrganizationId = (url: string): string | undefined => {
	if (url.indexOf('container_views?with=contracts,properties,model') >= 0) {
		return /\/organizations\/(.*)\/container_views/.exec(url)?.[1];
	}
};

const HEYLIOT_TRACKER = 'heyliot-tracker';
const getTracker = () => {
	const tracker = localStorage.getItem(HEYLIOT_TRACKER);
	if (!!tracker) {
		return JSON.parse(tracker);
	}
	return initDefaultTracker();
};

export const stopTracker = async () => {
	const tracker: Track = getTracker();
	if (!!tracker.sensorId && tracker?.steps?.length > 0) {
		tracker.endedAt = new Date();
		await sendUserLogs(tracker);
	}
	localStorage.removeItem(HEYLIOT_TRACKER);
};

export const fetchAndTrack = async (
	url: string,
	options?: RequestInit,
	name?: string
) => {
	let tracker = getTracker();
	let response = await fetch(url, options);

	let body;
	try {
		body = await response.clone().json();
	} catch (e) {}

	if (isFirstCall(url)) {
		await stopTracker();
		tracker = initDefaultTracker(body);
		localStorage.setItem(HEYLIOT_TRACKER, JSON.stringify(tracker));
	}

	const organizationId = getOrganizationId(url);
	if (!!organizationId) {
		tracker.organizationId = organizationId;
		localStorage.setItem(HEYLIOT_TRACKER, JSON.stringify(tracker));
	}

	if (
		options?.method &&
		['post', 'patch', 'put'].indexOf(options.method!.toLowerCase()) >= 0
	) {
		tracker.steps.push({
			url: url,
			name,
			body: options?.body ?? '{}',
			responseStatus: response.status,
			responseBody: (body && JSON.stringify(body)) ?? '{}',
			createdEntities: options.method.toLowerCase() === 'post' ? [url] : [],
			updatedEntities:
				['patch', 'put'].indexOf(options.method.toLowerCase()) >= 0 ? [url] : []
		});
		localStorage.setItem(HEYLIOT_TRACKER, JSON.stringify(tracker));
	}

	return response;
};
