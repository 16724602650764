import { useEffect, useState } from 'react';
import { getGroupList } from '../../../../../api';
import { useOriginCreateContainerContext } from '../OriginCreateContainerContext';
import { ApiGetGroup } from '../../../../../api/model/container';

export const useGroups = () => {
	const { organizationId } = useOriginCreateContainerContext();

	const [groupList, setGroupList] = useState<ApiGetGroup[]>([]);

	const getGroupListData = (nextUrl?: string) => {
		return getGroupList(organizationId, nextUrl).then(
			({ groupList: data, next }) => {
				if (data) {
					setGroupList([...groupList, ...data]);
					next && getGroupListData(next);
				}
			}
		);
	};

	useEffect(
		() => {
			getGroupListData();
		}, // eslint-disable-next-line
		[]
	);

	return groupList;
};
