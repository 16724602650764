import React, {PropsWithChildren, useContext, useEffect} from 'react';
import {useHistory} from 'react-router';
import './ErrorBoundaries.scss';
import {useT} from 'talkr';

const ErrorStatusContext = React.createContext((e: string) => {
    //this is intentional
});

export const useAsyncError = () => {
    const {T} = useT();
    const callback = useContext(ErrorStatusContext);
    return React.useCallback(
        (e: Error | string) => {
            if(typeof e === 'string'){
                console.error(e);
                callback(T(e));
                return;
            }
            console.error(e.message);
            callback(T(e.message));
        },
        [callback, T]
    );
};

export default function ErrorBoundaries({
                                            children
                                        }: PropsWithChildren) {
    const history = useHistory();

    const [error, setError] = React.useState<string | undefined>('');

    const contextPayload = React.useMemo(() => setError, [setError]);

    useEffect(() => {
        const interval = setInterval(() => {
            setError('');
        }, 3000);
        return () => clearInterval(interval);
    }, [error]);

    React.useEffect(() => {
        // Listen for changes to the current location && cleanup the listener on unmount
        return history.listen(() => setError(undefined));
    }, [history]);

    return (
        <ErrorStatusContext.Provider value={contextPayload}>
            {error && <div className="errorBlock" role="alert">{error}</div>}
            {children}
        </ErrorStatusContext.Provider>
    );
}
