import React, { useState } from 'react';
import { getCurrentPosition } from '../../../../api/geolocation';
import {
	ApiGetContainer,
	ApiPostContainer
} from '../../../../api/model/container';
import { createContainer } from '../../../../api';
import { ContainerForm, FormContainer } from './ContainerForm';
import { useContainerFormDataContext } from '../ContainerFormDataContext';
import { useHistory } from 'react-router';
import { useOriginCreateContainerContext } from './OriginCreateContainerContext';
import { useSensorContext } from '../../../../components/Context/Context';

export const CreationForm = () => {
	const history = useHistory();
	const { fromWizard } = useOriginCreateContainerContext();
	const sensorContext = useSensorContext();
	const { models, groups, flows } = useContainerFormDataContext();

	const [container, setContainer] = useState<FormContainer>(
		{} as FormContainer
	);

	const save = async (container: FormContainer) => {
		const coords = await getCurrentPosition();

		const geolocalisation = {
			longitude: coords.coords.longitude,
			latitude: coords.coords.latitude
		};

		const newContainer: ApiPostContainer = {
			...geolocalisation,
			name: container.name!,
			model: container.model?.value,
			flow: container.flow?.value,
			depositPoint: container.deposit?.value,
			logisticStatus: container.statut?.value,
			properties: models?.find(
				(model) => model['@id'] === container?.model?.value
			)?.properties
		};

		if (container.group?.value) {
			const result: ApiGetContainer = await createContainer(
				container.group.value!,
				newContainer
			).then((response: Response) => response.json());

			if (fromWizard) {
				sensorContext.setSensor({
					...sensorContext.sensor,
					container: {
						...(result as any),
						properties: {
							CONTAINER_EMPTY_MEASURE:
								result.properties?.CONTAINER_EMPTY_MEASURE!,
							CONTAINER_FULL_MEASURE: result.properties?.CONTAINER_FULL_MEASURE!
						}
					}
				});

				history.push('/wizard/container-validation?from=container-creation');
			} else {
				history.push('/containers-list?from=container-creation');
			}
		}
	};

	return (
		<div id="container-form">
			<h2>Créer un contenant</h2>
			<ContainerForm
				container={container}
				setContainer={setContainer}
				models={models}
				groups={groups}
				flows={flows}
				onSave={save}
			/>
		</div>
	);
};
