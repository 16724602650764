// REACT
import { useHistory } from 'react-router';
import {
	useContext,
	useEffect,
	useLayoutEffect,
	useRef,
	useState
} from 'react';
import pThrottle from 'p-throttle';

import 'react-html5-camera-photo/build/css/index.css';
import './WizardGeolocalisation.scss';
// CONTEXT/API
import SensorContext from '../../components/Context/Context';
import {getPlacesFromAzure} from '../../api';
import {getCurrentPosition} from '../../api/geolocation';
//COMPONENTS
import WizardFooter from '../../components/Footer/WizardFooter';
import WizardStepper from '../../components/WizardStepper/WizardStepper';
import CommentModal from '../../components/CommentModal/CommentModal';
import {
	createIcon,
	createMapController,
	createMarker,
	initMap,
	setMarkers
} from '../../lib/Helper/GeolocHelper';
import Autocomplete from 'react-autocomplete';
import { Button } from '../../lib/Buttons/Button';
import { createLogger } from '../../monitoring/logrocket';

let userPositionMarker;
let containerPositionMarker;
let newPositionMarker;
let steadyGroupMarkers;
let map;
const logger = createLogger('Geolocalisation Page');

const throttle = pThrottle({
	limit: 2,
	interval: 300
});

const getPlacesFromAzureThrottled = throttle((searchValue) =>
	getPlacesFromAzure(searchValue)
);
export default function WizardGeolocalisation() {
	const watch = useRef();
	const history = useHistory();
	const sensorContext = useContext(SensorContext);
	const [center, setCenter] = useState([]);
	const selectedContainer = sensorContext.sensor.container;

	const [updateMode, setUpdateMode] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const [showAddressCommentModal, setShowAddressCommentModal] = useState(false);
	const [addressComment, setAddressComment] = useState(
		sensorContext.sensor.container?.addressComment ?? ''
	);
	const [loading, setLoading] = useState(false);

	// action on container marker click
	const updateContainerPosition = useRef(() => {
		setUpdateMode(true);
	});

	useEffect(() => {
		if (updateMode) {
			document
				.getElementsByClassName('container-marker__position')[0]
				.classList.remove('container-marker__position--animated');

			// set new Marker and attribute geoloc everytime map moves
			newPositionMarker = createMarker(
				[selectedContainer.latitude, selectedContainer.longitude],
				createIcon('/images/new-map-point.svg', [22, 55], {
					className: 'temporary-marker__position'
				})
			).addTo(map);

			map.on('move', function () {
				const center = map.getCenter();
				newPositionMarker.setLatLng(center);
				setCenter(center);
			});

			map.setView(
				[selectedContainer.latitude, selectedContainer.longitude],
				18
			);
		}
	}, [updateMode, selectedContainer.latitude, selectedContainer.longitude]);

	const setupMap = (
		map,
		position,
		selectedContainer,
		updateContainerPosition
	) => {

		if(!map){
			return;
		}

		selectedContainer.latitude =
			selectedContainer.latitude || position.coords.latitude;
		selectedContainer.longitude =
			selectedContainer.longitude || position.coords.longitude;

		userPositionMarker = createMarker(
			[position.coords.latitude, position.coords.longitude],
			createIcon('/images/user_position.png', [22, 23], { iconSize: [40, 40] })
		);

		containerPositionMarker = createMarker(
			[selectedContainer.latitude, selectedContainer.longitude],
			createIcon('/images/map-point.svg', [23, 55], {
				className:
					'container-marker__position container-marker__position--animated'
			})
		);

		steadyGroupMarkers = setMarkers(
			[userPositionMarker, containerPositionMarker],
			map
		);

		map.fitBounds(steadyGroupMarkers.getBounds());

		containerPositionMarker.on('click', updateContainerPosition.current);

		// set new center on user position icon and modify zoom controll position
		map.zoomControl.setPosition('bottomright');
		window.L.Control.CurrentPosition = createMapController(map, (ev) => {
			window.L.DomEvent.stopPropagation(ev);
			window.L.DomEvent.preventDefault(ev);
			navigator.geolocation.getCurrentPosition((position) => {
				userPositionMarker.setLatLng([
					position.coords.latitude,
					position.coords.longitude
				]);
				map.panTo(userPositionMarker.getLatLng(), 18);
			});
		});

		window.L.control.currentPosition = function (opts) {
			return new window.L.Control.CurrentPosition(opts);
		};

		window.L.control.currentPosition({ position: 'bottomright' }).addTo(map);

		watch.current = navigator.geolocation.watchPosition((position) => {
			userPositionMarker.setLatLng([
				position.coords.latitude,
				position.coords.longitude
			]);
		});
	};
	useLayoutEffect(() => {
		getCurrentPosition().then(position => {
			console.log('[WizardGeolocalisation.js::getCurrentPosition] (line #157)]', position);			try {
				map = initMap();
				setupMap(map, position, selectedContainer, updateContainerPosition);
			} catch (e) {
				console.warn('[WizardGeolocalisation.js::getCurrentPosition] (line #162)] The Map is not initialized', );			}
		})
		return () => {
			if(watch.current){
				navigator.geolocation.clearWatch(watch.current);
			}
		}
	}, [selectedContainer, updateContainerPosition]);

	useEffect(() => {
		if (searchValue) {
			getPlacesFromAzureThrottled(searchValue).then((places) =>
				setSearchResults(places ?? [])
			);
		}
	}, [searchValue]);

	const keepCoord = () => {
		const sensor = {
			...sensorContext.sensor,
			coords: {
				lat: selectedContainer?.latitude,
				lng: selectedContainer?.longitude
			},
			keepSensorCoordinates: true
		};
		logger.log('Keep coordinates', JSON.stringify(sensor));

		setLoading(true);
		sensorContext.setSensor(sensor);
		setShowAddressCommentModal(true);
		setLoading(false);
	};

	return (
		<div id="Geolocalisation" className="page">
			{showAddressCommentModal && (
				<CommentModal
					title="Commentaire sur l'emplacement"
					instructions="Voulez-vous préciser des informations complémentaires à l'emplacement ?"
					cancel={() => {
						setShowAddressCommentModal(false);
					}}
					ok={() => {
						sensorContext.setSensor({
							...sensorContext.sensor,
							container: {
								...sensorContext.sensor.container,
								addressComment:
									addressComment != null &&
									!addressComment.replace(/\s/g, '').length
										? null
										: addressComment
							}
						});

						history.push('/wizard/summary');
					}}
					pass={true}
					passAction={() => {
						history.push('/wizard/summary');
					}}
					loader={loading}
				>
					<textarea
						className="comment-modal__textarea"
						value={addressComment}
						onChange={(event) => setAddressComment(event.target.value)}
						placeholder="exemple: le contenant se trouve à gauche du poteau électrique."
					/>
				</CommentModal>
			)}

			<div id="SearchBlock" className="container-geolocation">
				<h2>
					{updateMode ? "Affiner l'emplacement" : 'Emplacement du contenant'}
				</h2>
				{updateMode && (
					<div className="search-bar">
						<Autocomplete
							getItemValue={(item) => item.address.freeformAddress}
							items={searchResults}
							renderItem={(item) => {
								return (
									<div className="search-bar__item">
										{item.address.freeformAddress}
									</div>
								);
							}}
							renderMenu={(items, value, style) => {
								return (
									<div
										style={{ ...style }}
										className="search-bar__menu"
										children={items}
									/>
								);
							}}
							wrapperProps={{ className: 'search-bar__wrapper' }}
							inputProps={{
								placeholder: "exemple: 13a Quai d'Ille et Rance, 35000 Rennes"
							}}
							value={searchValue}
							onChange={(e) => setSearchValue(e.target.value)}
							onSelect={(label, item) => {
								const center = [item.position.lat, item.position.lon];
								setSearchValue(label);
								newPositionMarker.setLatLng(center);
								setCenter(center);
								map.fitBounds([[center]]).setZoom(17);
							}}
						/>
					</div>
				)}
				<div id="map" />
			</div>

			{updateMode && (
				<div className="update-container-geoloc">
					<h3 className="update-container-geoloc__title">
						{selectedContainer.name}
					</h3>
					<div className="update-container-geoloc__buttons">
						<Button
							label="Annuler"
							type="button"
							onClick={() => {
								setUpdateMode(false);
								map.removeLayer(newPositionMarker);
								map.fitBounds(steadyGroupMarkers.getBounds());
								document
									.getElementsByClassName('container-marker__position')[0]
									.classList.add('container-marker__position--animated');
							}}
							classModifiers={['white']}
						/>
						<button
							className="btn btn--small update-container-geoloc__buttons--update"
							onClick={() => {
								sensorContext.setSensor({
									...sensorContext.sensor,
									coords: center
								});
								setShowAddressCommentModal(true);
							}}
						>
							Modifier
						</button>
					</div>
				</div>
			)}

			{!updateMode && (
				<>
					<WizardStepper activeIndex={8} />
					<WizardFooter
						previous={() => {
							history.goBack();
						}}
						next={keepCoord}
						nextLabel="conserver"
					/>
				</>
			)}
		</div>
	);
}
