import { Option } from '../../../../../api/model';

export const convertModelToOption = (model: any): Option | undefined => {
	if (!model) {
		return;
	}

	const prefix = model['brand'] ? model['brand'].name + ' - ' : '';
	return {
		value: model['@id'],
		label: `${prefix}${model.name} (${model.type})`
	};
};
