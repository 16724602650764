export type ServerSideApiPayload<T> = {
	'hydra:member': T[];
	'hydra:view': {
		'hydra:next': string | null;
	};
	'hydra:totalItems': number;
};

export type ClientSideApiPayload<T> = {
	data?: T[];
	next?: string | null;
	totalItem?: number;
};

export const PENDING = 'PENDING';
export const FOUND = 'FOUND';
export const NOTFOUND = 'NOT_FOUND';

type FetchArgumentsWithoutName = [string, RequestInit];
type FetchArgumentsWithName = [string, RequestInit, string];

export type FetchArguments = FetchArgumentsWithName | FetchArgumentsWithoutName;

export type Coordinate = {
	latitude: number;
	longitude: number;
};

type NetWorkType = {
	LORA: string;
	SIGFOX: string;
};
export const Network: NetWorkType = {
	LORA: 'LoRa',
	SIGFOX: 'Sigfox'
};
export type NetWorkKey = keyof NetWorkType;

type ProviderType = {
	SIGFOX: string;
	LIVE_OBJECT: string;
	OBJENIOUS: string;
	PROXIMUS: string;
	HEYWANESYTESTER: string;
	SWISS_COM: string;
	MILE_SIGHT: string;
	LORIOT: string;
	HELIUM: string;
};
export const Provider: ProviderType = {
	SIGFOX: 'Sigfox',
	LIVE_OBJECT: 'Live Object',
	OBJENIOUS: 'Objenious',
	PROXIMUS: 'Proximus',
	HEYWANESYTESTER: 'HeyWanesyTester',
	SWISS_COM: 'SwissCom',
	MILE_SIGHT: 'MileSight',
	LORIOT: 'Loriot',
	HELIUM: 'Helium'
} as const;

export type ProviderKey = keyof ProviderType;

export type Option = {
	value: string;
	label: string;
	style?: `${string}-status`;
};
export type Options = Option[];
