import {useState} from "react";

export const useSearchHistory = () => {
    const email = localStorage.getItem('email') ?? '';
    const getAndParseHistory = () => {
        const previousIdsFromLS = localStorage.getItem(`${email}_sensorIds_search_history`);
        if (previousIdsFromLS) {
            return JSON.parse(previousIdsFromLS);
        }
        return [];
    }

    const [previousIds, setPreviousIds] = useState<string[]>(getAndParseHistory);

    const updateHistory = (value: string) => {
        const newHistory = Array.from(new Set([value, ...previousIds.slice(0, 9)]));
        setPreviousIds(newHistory);
        localStorage.setItem(`${email}_sensorIds_search_history`, JSON.stringify(newHistory));
    }

    return { previousIds, updateHistory };
}
