import {DateFromNow} from "../../../lib/Helper/DateHelper/DateHelper";
import {getNetworkLabel, getProviderLabel} from "../../../api/utils";
import {SensorMeasure} from "../../../components/Context/ContextType";


type MeasureProps = {
    messageIfUndefined: string
    measure: SensorMeasure
}

export const Measure = ({measure, messageIfUndefined}: MeasureProps) => {
    if (!measure?.value && !measure?.date) {
        return <dd>{messageIfUndefined}</dd>;
    }
    return (
        <>
            <dd>
                {!!measure.value && <><span>{measure.value}mm</span>&nbsp;</>}
                {DateFromNow({
                    date: measure.date,
                    locale: 'fr',
                    prefix: ''
                })}
            </dd>
            <dd>
                {getProviderLabel(measure.provider)} ({getNetworkLabel(measure.network)}
                )
            </dd>
        </>
    );
};
