import './Animation.scss';

type Props = {
	path: string;
};

export const Cover = ({ path }: Props) => {
	return (
		<div className="cover">
			<picture>
				<source srcSet={path + '.webp'} type="image/webp" />
				<img src={path + '.png'} alt="" />
			</picture>
		</div>
	);
};
