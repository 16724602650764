import Warning from '../../svg/warning';
import Modal from '../Modal/Modal';
import './SensorActiveContractModal.scss';
import { Sensor } from '../Context/ContextType';

type SensorActiveContractModalProps = {
	sensor: Sensor;
	ok?: any;
	cancel?: any;
};

export default function SensorActiveContractModal({
	sensor,
	ok,
	cancel
}: SensorActiveContractModalProps) {
	return (
		<Modal>
			<h3>Attention</h3>
			<p>
				Ce <strong>capteur {sensor.serialNumber}</strong> est déjà associé au{' '}
				<strong>Contenant {sensor.contract.container.name}</strong>.
				Souhaitez-vous l'installer dans un nouveau contenant ?
			</p>
			<p>
				<Warning />{' '}
				<span>
					La précédente association sera automatiquement supprimée lorsque vous
					aurez terminé l'installation.
				</span>
			</p>
			<p>
				<button
					type="button"
					className="btn btn--small btn--white"
					onClick={cancel}
				>
					<span className="btn__label">Annuler</span>
				</button>
				<button type="button" className="btn btn--small" onClick={ok}>
					<span className="btn__label">Ok</span>
				</button>
			</p>
		</Modal>
	);
}
