import {Button} from "../../../lib/Buttons/Button";
import "./previousSearchedIds.scss";

type PreviousSearchedIdsProps = {
    previousIds: string[]
    onSearch: (id: string) => void
}
export const PreviousSearchedIds = ({ previousIds, onSearch }: PreviousSearchedIdsProps) => {
    if(previousIds.length === 0){
        return null;
    }
    return (
        <ul className="previous-searched-ids">
            {previousIds.map((id: string) => <li key={id}><Button
                type="button"
                label={id}
                classModifiers={['small']}
                onClick={() => onSearch(id)}
            /></li>)}

        </ul>
    )
}
