import { ApiGetDepositPoint } from '../../../../../api/model/container';
import { useEffect, useState } from 'react';
import {
	fetchAllDepositPoints,
	generateDepositPointsUrl
} from '../../../../../api/depositPoints';
import { useOriginCreateContainerContext } from '../OriginCreateContainerContext';

export const useDepositPoints = (): ApiGetDepositPoint[] => {
	const { organizationId } = useOriginCreateContainerContext();
	const [depositPoints, setDepositPoints] = useState<ApiGetDepositPoint[]>([]);

	useEffect(() => {
		fetchAllDepositPoints(
			organizationId,
			`${generateDepositPointsUrl(organizationId)}`
		).then(setDepositPoints);
	}, [organizationId]);

	return depositPoints;
};
