import { ChangeEvent } from 'react';
import {} from '../../../../../api/model';
import { Checkbox } from '../../../../../lib/Forms/Checkbox/Checkbox';
import './TagsCheckboxGroup.scss';
import { tagsOptions } from './tagsOptions';

type TagsCheckboxGroupTypes = {
	value?: string[];
	onChange: (value: string[]) => void;
};
export const TagsCheckboxGroup = ({
	value = [],
	onChange
}: TagsCheckboxGroupTypes) => {
	const onChangeHandler = (e: ChangeEvent<HTMLFieldSetElement>) => {
		if (!e?.target) {
			return null;
		}
		const checkbox = e.target as unknown as HTMLInputElement;

		if (value?.includes(checkbox.value!)) {
			onChange(value?.filter((tag) => tag !== checkbox.value));
		} else {
			onChange([...value, checkbox.value]);
		}
	};
	return (
		<fieldset className="tags_checkbox_group" onChange={onChangeHandler}>
			<legend>Tag</legend>
			{tagsOptions.map((tag) => (
				<Checkbox
					key={tag.value}
					label={tag.label}
					value={tag.value}
					checked={value?.includes(tag.value)}
				/>
			))}
		</fieldset>
	);
};
