import { Container } from '../../../../components/Context/ContextType';
import Warning from '../../../../svg/warning';
import Modal from '../../../../components/Modal/Modal';
import React from 'react';

type ContainerActiveContractsWarningProps = {
	container: Container;
	currentSerialNumber: string;
	onCancel: () => void;
	onAdd: () => void;
	onReplace: () => void;
};
export const ContainerActiveContractsWarning = ({
	container,
	currentSerialNumber,
	onCancel,
	onAdd,
	onReplace
}: Readonly<ContainerActiveContractsWarningProps>) => {
	const serialNumbers = container.activeContracts
		.map((contract: any) => contract.sensor.serialNumber)
		.join(', ');
	return (
		<Modal>
			<h3>Attention</h3>
			<p>
				Ce <strong>contenant {container.name}</strong> est déjà associé avec le
				ou les capteurs <strong>{serialNumbers}</strong>. Souhaitez-vous tout de
				même installer le capteur <strong>{currentSerialNumber}</strong> dans ce
				contenant ?
			</p>
			<p>
				<Warning />{' '}
				<span>
					Si j'ajoute un capteur, ce contenant aura plusieurs contrats actifs.
					Si je remplace, je mets fin à tous ses contrats actifs.
				</span>
			</p>
			<p className="flex-justify-around">
				<button className="btn btn--white" type="button" onClick={onCancel}>
					<span className="btn__label">Annuler</span>
				</button>
				<button
					type="button"
					className="btn btn--small btn--secondary"
					onClick={onAdd}
				>
					<span className="btn__label">Ajouter</span>
				</button>
				{container.activeContracts.length > 0 && (
					<button type="button" className="btn btn--small" onClick={onReplace}>
						<span className="btn__label btn__label--white">Remplacer</span>
					</button>
				)}
			</p>
		</Modal>
	);
};
