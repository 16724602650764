import './RetryButton.scss';
import { Button } from '../../lib/Buttons/Button';

type RetryButtonProps = {
	onClick: any;
};
export default function RetryButton({ onClick }: RetryButtonProps) {
	return (
		<Button
			label="Réessayer"
			type="button"
			classModifiers={['small', 'retry']}
			onClick={onClick}
		/>
	);
}
