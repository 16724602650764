export const FlashOn = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18.938"
			height="28.407"
			viewBox="0 0 18.938 28.407"
		>
			<g id="flash" transform="translate(-5)">
				<path
					id="Tracé_513"
					data-name="Tracé 513"
					d="M10.682,28.407,13.246,12.94H5L11.115,0l8.759.116L16.048,7.575h7.891Z"
				/>
			</g>
		</svg>
	);
};

export const FlashOff = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18.938"
			height="28.407"
			viewBox="0 0 18.938 28.407"
		>
			<g id="flash" transform="translate(-5)">
				<path
					id="Tracé_513"
					data-name="Tracé 513"
					d="M10.682,28.407,13.246,12.94H5L11.115,0l8.759.116L16.048,7.575h7.891Z"
				/>
			</g>
		</svg>
	);
};
