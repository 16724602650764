import { createContext, useContext } from 'react';
import {
	ApiGetDepositPoint,
	ApiGetFlow,
	ApiGetGroup,
	ApiGetModel
} from '../../../api/model/container';

export type ContainerFormDataContextType = {
	models: ApiGetModel[];
	groups: ApiGetGroup[];
	flows: ApiGetFlow[];
	depositPoints: ApiGetDepositPoint[];
};
export const ContainerFormDataContext = createContext<
	ContainerFormDataContextType | undefined
>(undefined);

export const useContainerFormDataContext = () => {
	const context = useContext(ContainerFormDataContext);
	if (!context) {
		throw new Error(
			'The provider for the OriginCreateContainerContext is not defined'
		);
	}
	return context;
};
