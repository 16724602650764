import { createContext, useContext } from 'react';

export type OriginCreateContainerContextType = {
	fromWizard: boolean;
	organizationId: string;
	urlPrefix: string;
};
export const OriginCreateContainerContext = createContext<
	OriginCreateContainerContextType | undefined
>(undefined);

export const useOriginCreateContainerContext = () => {
	const context = useContext(OriginCreateContainerContext);
	if (!context) {
		throw new Error(
			'The provider for the OriginCreateContainerContext is not defined'
		);
	}
	return context;
};
