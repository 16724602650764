// React
import { useHistory } from 'react-router';
import React, {useContext, useState} from 'react';
// Components
import WizardFooter from '../../components/Footer/WizardFooter';
import WizardStepper from '../../components/WizardStepper/WizardStepper';
import SignalStrengthIcon from '../../components/SignalStrengthIcon/SignalStrengthIcon';
import { useAsyncError } from '../../components/ErrorBoundaries/ErrorBoundaries';
import CommentModal from '../../components/CommentModal/CommentModal';
import 'react-html5-camera-photo/build/css/index.css';
import './WizardSummary.scss';
//Scss
import 'react-html5-camera-photo/build/css/index.css';
import './WizardSummary.scss';
//Context
import SensorContext from '../../components/Context/Context';
import { SensorContextType } from '../../components/Context/ContextType';
//api
import {
	endContainerActiveContractByIri,
	tagContainer,
	tagSensor,
	updateSensorAndCreateContract,
	updateSensorOrganisation
} from '../../api';
import { stopTracker } from '../../api/tracker';
import Tag, { ContainerTag, StatusTag } from '../../components/Tag';
import { ICONS } from '../../components/Icons/icons';
import pThrottle from "p-throttle";

export default function WizardSummary() {
	const history = useHistory();
	const sensorContext: SensorContextType = useContext(SensorContext);
	const [pending, setPending] = useState(false);
	const [hasError, setHasError] = useState(false);
	const asyncError = useAsyncError();
	const [wizardNoteModal, setWizardNoteModal] = useState(false);
	const [wizardNote, setWizardNote] = useState(
		sensorContext.sensor.container?.note ?? ''
	);

	const validate = async () => {
		setPending(true);

		const sensor = sensorContext.sensor;
		if (sensor.removeContainerContracts) {
			for (let activeContract of sensor.container.activeContracts) {
				await endContainerActiveContractByIri(activeContract['@id'], {
					endedAt: new Date().toISOString()
				});
			}
		}

		/**
		 * Si nous avons enregistrez lors du wizard des capteurs a taggué, nous notifions l'API
		 */
		if (sensor.tagKoSensors) {
			for (let serialNumber of sensor.tagKoSensors) {
				await tagSensor(
					serialNumber,
					sensor.tagValueKoSensors ?? 'sensor-ko'
				);
			}
		}

		/**
		 * Si nous avons passé l'étape de la mesure outdoor
		 */
		if (!!sensor.tagMissOutdoor) {
			await tagSensor(sensor.serialNumber, 'install-measure-skipped');
		}

		/**
		 * Si nous avons indiquer que nous ne connaissions pas les mesures du container, nous le taggons
		 */
		if (sensor.unknownContainerMeasure) {
			await tagContainer(sensor.container.id, 'unclear-boundaries');
		}

		/**
		 * Si nous avons demander un changement d'organisation pour le sensor
		 */
		if (!!sensor.updateSensorOrganisation) {
			await updateSensorOrganisation(
				sensor.updateSensorOrganisation.organzationId,
				sensor.updateSensorOrganisation.sensorId
			);
			await tagSensor(sensor.serialNumber, 'switched-from-spare');
		}

		updateSensorAndCreateContract(sensorContext.sensor)
			.then(() => {
				setPending(false);
				history.push('/wizard/end');
			})
			.catch((error) => {
				if (hasError) {
					setPending(true);
					asyncError(`Veuillez contacter un administrateur technique.`);
				} else {
					setHasError(true);
					setPending(false);
					asyncError(error);
				}
			})
			.finally(() => {
				stopTracker();
			});
	}
	return (
		<div id="WizardSummary" className="page">
			{wizardNoteModal && (
				<CommentModal
					title="Commentaire d'installations"
					instructions="Voulez-vous préciser des informations complémentaires à cette installation ?"
					ok={() => {
						sensorContext.setSensor({
							...sensorContext.sensor,
							container: {
								...sensorContext.sensor.container,
								note: wizardNote
							}
						});

						setWizardNoteModal(false);
					}}
					cancel={() => {
						setWizardNoteModal(false);
					}}
					loader={pending}
				>
					<textarea
						className="comment-modal__textarea"
						value={wizardNote}
						onChange={(event) => setWizardNote(event.target.value)}
						placeholder="exemple: On a pas utilisé les vis X mais A."
					/>
				</CommentModal>
			)}
			<div id="content" className="summary">
				<h2>Récapitulatif</h2>
				<div className="summary__blocks">
					<div className=" summary__blocks_sections summary__contract-report">
						<dl>
							<dt>Capteur</dt>
							<dd>{sensorContext.sensor.serialNumber}</dd>
						</dl>
					</div>
					<div className="summary__blocks_sections summary__contract-report summary__signal-strength ">
						<dl>
							<dt>Réseau</dt>
							<dd>
								<span>
									{sensorContext.sensor.provider === 'LIVE_OBJECT'
										? 'LoRa'
										: sensorContext.sensor.provider}
								</span>
								<SignalStrengthIcon
									signalStrength={sensorContext.sensor.signalStrength}
								/>
							</dd>
						</dl>
					</div>
					<div className="summary__blocks_sections summary__contract-report">
						<dl>
							<dt>Mesure (à l'extérieur)</dt>
							<dd>
								{sensorContext.sensor.outdoorMeasure?.measureValue
									? sensorContext.sensor.outdoorMeasure.measureValue + 'mm'
									: 'indéfinie'}
							</dd>
							<dt>Mesure (à l'intérieur)</dt>
							<dd>
								{sensorContext.sensor.indoorMeasure?.measureValue
									? sensorContext.sensor.indoorMeasure.measureValue + 'mm'
									: 'indéfinie'}
							</dd>
						</dl>
					</div>
					<div className="summary__blocks_sections summary__contract-report">
						<dl className="column dl-container">
							<dt>Contenant</dt>
							<dd>
								<img
									src={
										sensorContext.sensor.container?.picture
											? sensorContext.sensor.container?.picture +
											  '?width=48&height=60'
											: '/images/placeholder_container.svg'
									}
									alt=""
								/>
								<div className="container">
									<h3>{sensorContext.sensor.container.name}</h3>
								</div>
							</dd>
							<dd>
								<StatusTag
									logisticStatus={sensorContext.sensor.container.logisticStatus}
								/>
								<ContainerTag tags={sensorContext.sensor.container.tags} />
								{!!sensorContext.sensor.container.depositPoint && (
									<Tag
										label={sensorContext.sensor.container.depositPoint.name}
										classModifiers={['default-status']}
										iconLeft={ICONS.CIRCLE}
									/>
								)}
							</dd>
						</dl>
					</div>
					{wizardNote && (
						<div className="summary__blocks_sections summary__contract-report summary__wizard-note">
							<dl className="column">
								<dt>
									Note
									<button
										type="button"
										onClick={() => {
											setWizardNoteModal(true);
										}}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
										>
											<path
												fillRule="evenodd"
												d="M15.9112591,2.94203252 C17.340763,1.64749001 19.5509971,1.68663814 20.9339327,3.06276128 L20.9339327,3.06276128 L21.0927347,3.23279602 C22.3655572,4.67974468 22.2953924,6.88668378 20.9057988,8.244221 L20.9057988,8.244221 L8.85219368,20.2944929 C8.84635266,20.3003323 8.84040374,20.3060482 8.83435108,20.311638 C8.82837594,20.3161868 8.82256029,20.3212766 8.8166383,20.3262662 C8.74521415,20.3885573 8.66041579,20.4330517 8.56874574,20.4570718 L8.56874574,20.4570718 L2.78401525,21.9728345 C2.32153715,22.0940169 1.8997972,21.6721902 2.02107455,21.2097371 L2.02107455,21.2097371 L3.53767056,15.4266731 C3.54217742,15.4094876 3.54740388,15.3925438 3.55332624,15.375883 L3.56738,15.3399106 C3.56828317,15.3377942 3.56919663,15.3356853 3.57012031,15.333584 C3.57682855,15.3183365 3.58412227,15.3034038 3.59199625,15.2887885 C3.60182171,15.2704303 3.61238849,15.2529214 3.62369307,15.2360916 C3.64608271,15.2030214 3.67172877,15.1718297 3.70033913,15.1432272 L3.64492558,15.2064038 C3.6591392,15.1877163 3.6743068,15.1699759 3.69032714,15.1532234 L3.70033913,15.1432272 L15.7538831,3.0930164 Z M4.562,16.444 L3.50131354,20.4927429 L7.554,19.43 L6.65798384,17.338296 L4.562,16.444 Z M16.244,8.636 L7.874,17.003 L8.624,18.754 L17.494,9.886 L16.244,8.636 Z M14.11,6.503 L5.237,15.373 L6.99,16.12 L15.36,7.753 L14.11,6.503 Z M15.85,4.763 L14.993,5.619 L18.378,9.002 L19.235,8.146 L15.85,4.763 Z M19.9368011,3.84108472 C18.9918873,3.01554226 17.5544313,3.05507343 16.6560579,3.95852622 L16.737,3.882 L20.1178288,7.26180152 C20.9360716,6.37213298 20.9605464,5.00882948 20.164684,4.07202183 L20.0468274,3.94264596 Z"
											/>
										</svg>
									</button>
								</dt>
								<dd>
									<p>{wizardNote}</p>
								</dd>
							</dl>
						</div>
					)}
					{!wizardNote && (
						<button
							type="button"
							className="summary__wizard-note_button"
							onClick={() => {
								setWizardNoteModal(true);
							}}
						>
							Ajouter une note
						</button>
					)}
				</div>
			</div>
			<WizardStepper activeIndex={9} />
			<WizardFooter
				disabledNext={pending}
				nextLabel={hasError ? 'Réessayer' : 'Valider'}
				next={pThrottle({
					limit: 1,
					interval: 1000
				})(validate)}
				loader={pending}
			/>
		</div>
	);
}
