import { SensorContextType } from '../../../components/Context/ContextType';
import { useSensorContext } from '../../../components/Context/Context';
import { isContainerReadOnlyOrganization } from '../../../domain/organization-rule';
import { NavLink, useRouteMatch } from 'react-router-dom';
import React from 'react';
import { ContainerRoutes } from './ContainerRoutes';

const ContainerTabsBar = () => {
	const sensorContext: SensorContextType = useSensorContext();
	let { url } = useRouteMatch();

	if (isContainerReadOnlyOrganization(sensorContext.getUserOrganization())) {
		return null;
	}

	return (
		<nav id="container-selector__menu">
			<ul>
				<li className="ContainerSelectorMenu__navlink">
					<NavLink
						activeClassName="activeLink"
						to={`${url}/list`}
						replace={true}
					>
						{' '}
						Liste{' '}
					</NavLink>
				</li>
				<li className="ContainerSelectorMenu__navlink">
					<NavLink
						activeClassName="activeLink"
						to={`${url}/create`}
						replace={true}
					>
						{' '}
						Création{' '}
					</NavLink>
				</li>
			</ul>
		</nav>
	);
};
export const ContainerTabs = () => {
	return (
		<>
			<ContainerTabsBar />
			<ContainerRoutes />
		</>
	);
};
