import './WizardCommonStyle.scss';
import './WizardStartSensor.scss';
import WizardFooter from '../../components/Footer/WizardFooter';
import WizardStepper from '../../components/WizardStepper/WizardStepper';
import { useHistory } from 'react-router';
import { useContext, useState, useCallback } from 'react';
import SensorContext from '../../components/Context/Context';
import Loading from '../../components/FabButton/Loading/Loading';
import RetryButton from '../../components/RetryButton/RetryButton';
import { getProviderAndStrength } from '../../api';
import { getNetworkLabel, getProviderLabel, pollData } from '../../api/utils';
import { SensorSerialNumberWithIcon } from '../../components/SensorSerialNumberWithIcon/SensorSerialNumberWithIcon';
import { useAsyncError } from '../../components/ErrorBoundaries/ErrorBoundaries';
import { Cover } from '../../components/Animation/Animation';
import { SensorContextType } from '../../components/Context/ContextType';
import { DateFromNow } from '../../lib/Helper/DateHelper/DateHelper';
import {SignalStrength} from "../../components/SignalStrength";
import {Information} from "./Start/Information";
import {FOUND, NOTFOUND, PENDING} from "../../api/model"; // optional




export default function WizardStartSensor() {
	const history = useHistory();
	const sensorContext: SensorContextType = useContext(SensorContext);
	const [status, setStatus] = useState(NOTFOUND);
	const throwError = useAsyncError();

	const getProviderAndStrengthData = useCallback(() => {
		setStatus(PENDING);
		pollData((resolve) => {
			// @ts-ignore
			return getProviderAndStrength(sensorContext.sensor.serialNumber)
				.then(resolve)
				.catch((reason) =>
					console.warn('getProviderAndStrength failed', reason)
				);
		}, 'error.no_first_uplink_today')
			.then((providerAndStrength) => {
				sensorContext.setSensor({
					...sensorContext.sensor,
					...providerAndStrength
				});
				setStatus(FOUND);
			})
			.catch((err: any) => {
				throwError(err);
				setStatus(NOTFOUND);
			});
	}, [sensorContext, throwError]);

	const goNext = () => {
		if (status === NOTFOUND) {
			getProviderAndStrengthData();
		} else {
			history.push('/wizard/measure');
		}
	};


	return (
		<div id="StartSensor" className="page wizard-page">
			<Cover path="/images/big/sensor_start" />
			<div className="wizard-page__content">
				<h2>Démarrage du capteur</h2>
				<SensorSerialNumberWithIcon
					serialNumber={sensorContext.sensor.serialNumber}
				/>
				{status === NOTFOUND && (
					<Information sensor={sensorContext.sensor} />
				)}
				{status === PENDING && <Loading text="En attente de communication" />}
				{status === FOUND && (
					<div id="result">
						<p>Le capteur est démarré. Réseau sélectionné :</p>
						<div id="network" className="network">
							<div className="network-flex">
								<p className="network-flex--provider">
									<img
										className="network-flex--img"
										src="/images/share.svg"
										alt="network icon"
									/>
									<span id="networkProvider">
										{getProviderLabel(sensorContext.sensor.provider)} (
										{getNetworkLabel(sensorContext.sensor.network)})
									</span>
								</p>
								<SignalStrength signalStrength={sensorContext.sensor.signalStrength} rssi={sensorContext.sensor.rssi} snr={sensorContext.sensor.snr}/>
							</div>
							<DateFromNow
								date={sensorContext.sensor.lastSignalDate}
								locale="fr"
								prefix="Allumé"
							/>
						</div>

						<RetryButton onClick={() => getProviderAndStrengthData()} />
					</div>
				)}
			</div>

			<WizardStepper activeIndex={1} />
			<WizardFooter
				disabledNext={status === PENDING}
				next={() => goNext()}
				previous={() => {
					history.goBack();
				}}
			/>
		</div>
	);
}
