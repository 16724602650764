import {fetchAndTrack} from "./tracker";
import {clearLocalStorage} from "./clearLocalStorage";
import dayjs from "dayjs";
import {commonHeaders} from "./utils";
import {fetchWithAuthentication} from "./fetchWithAuthentication";

const authPrefixUrl = process.env.REACT_APP_AUTH_API;
const host = process.env.REACT_APP_DATA_API;


export function authenticate(email: string, password: string) {
    return fetchAndTrack(`${authPrefixUrl}/authenticate`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({ email, password, app_key: 'MOBILE_INSTALLER' })
    })
        .then((response) => {
            if (response.status > 400) {
                throw new Error('Authorization Exception');
            }
            return response.json();
        })
        .then(({ data }) => {
            localStorage.setItem('email', email);
            localStorage.setItem('access_token', data.accessToken);
            localStorage.setItem('refresh_token', data.refreshToken);
            localStorage.setItem('refresh_expire_at', data.refreshExpireAt);
            localStorage.setItem('access_expire_at', data.accessExpireAt);
        });
}

export function revokeAuthentication() {
    return fetchAndTrack(`${authPrefixUrl}/revoke`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            refreshToken: localStorage.getItem('refresh_token')
        })
    }).then(() => {
        clearLocalStorage()
    });
}

export function isAuthenticated() {
    return (
        localStorage.getItem('access_token') &&
        localStorage.getItem('refresh_expire_at') &&
        dayjs().isBefore(
            dayjs(parseInt(localStorage.getItem('refresh_expire_at')!) * 1000)
        )
    );
}


export function authorize() {
    const accessToken = localStorage.getItem('access_token');
    return fetch(`${authPrefixUrl}/authorize`, {
        method: 'POST',
        headers: {
            ...commonHeaders(),
            'content-type': 'application/json'
        },
        body: JSON.stringify({ accessToken })
    })
        .then((response) => response.json())
        .then((body) => {
            return body.data?.userId;
        });
}

export async function getUserInfo() {
    const userId = await authorize();
    if (!userId) {
        clearLocalStorage();
        window.location.pathname = '/login';
        return Promise.resolve();
    } else {
        return fetchWithAuthentication(
            `${host}/v2/users/${userId}?with=roles`
        ).catch((error) => console.error('Error when fetching user informations'));
    }
}
