import Tag from "./";
import {ICONS} from "../Icons/icons";

type ContainerFlowTagProps = {
    flow: string,
    flows: {
        key: string
        label: string
        color: string
    }[]
}

export const ContainerFlowTag = ({ flow, flows }: ContainerFlowTagProps) => {
    if(!flows){
        return null;
    }
    const currentFlow = flows.find(f => f.key === flow);
    const currentColor = currentFlow?.color ?? "#808080";
    const backgroundColor = `${currentColor}10`;
    if(!!currentFlow){
        return <Tag
            styles={{color: currentColor, backgroundColor: backgroundColor}}
            label={currentFlow!.label ?? ''}
            iconLeft={ICONS.CIRCLE}/>
    }
    return null;
}
