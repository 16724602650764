import Header from '../components/Header/Header';
import React, { useEffect, useState } from 'react';
import Loading from '../components/FabButton/Loading/Loading';
import { Button } from '../lib/Buttons/Button';
import { exportOrganizationLogs, exportUserLogs, getUserLogs } from '../api';
import dayjs from 'dayjs';
import './Install.scss';
import {FOUND, NOTFOUND} from "../api/model";


type InstallUnit = {
	count: number,
	name: string,
	id: string,
};

type Install = {
	count: number,
	date: string,
	byOrganization: InstallUnit[]
};

type InstallByOrganizationProps = {
	install: Install,
	date: string,
	callback: () => void
};

const InstallByOrganization = ({ install, date, callback }: InstallByOrganizationProps) => {
	if (install.byOrganization?.length > 1) {
		return (
			<ul>
				{install.byOrganization.map((install, index) => {
					return (
						<li key={index}>
							<span>
								{install.count} pour {install.name}
							</span>
							<Button
								label={<img alt="" src="/images/export.svg" />}
								onClick={() => {
									exportOrganizationLogs(install.id, { date }).then(() =>
										callback()
									);
								}}
								type="button"
								classModifiers={['small']}
								ariaLabel="Recevoir par mail"
							/>
						</li>
					);
				})}
			</ul>
		);
	}
	return <></>;
};
export default function Installs() {
	const [installs, setInstalls] = useState([]);
	const [status, setStatus] = useState(NOTFOUND);
	const [validated, setValidated] = useState(false);

	useEffect(
		() => {
			getUserLogs().then((installs) => {
				setStatus(FOUND);
				setInstalls(installs ?? []);
			});
		}, // eslint-disable-next-line
		[]
	);

	useEffect(() => {
		const interval = setInterval(() => {
			setValidated(false);
		}, 2000);
		return () => clearInterval(interval);
	}, [validated]);

	return (
		<>
			{validated && (
				<div className="installations-information">
					L'export a été envoyé par mail
				</div>
			)}
			<Header></Header>
			<div id="Search" className="page">
				<h2>Installations</h2>
				{status === NOTFOUND && <Loading />}
				{status === FOUND && (
					<ul id="searchResult" className="SearchResult installations">
						{installs.map((install: Install) => {
							const date = dayjs(install.date).format('DD/MM/YYYY'); // '25/01/2019'
							return (
								<li key={date}>
									<div className="global-installs">
										<h3>
											{install.count} installations le {date}{' '}
											{install.byOrganization?.length === 1 && (
												<>({install.byOrganization[0]?.name})</>
											)}
										</h3>

										<Button
											type="button"
											label={<img alt="" src="/images/export.svg" />}
											classModifiers={['small']}
											onClick={() => {
												exportUserLogs({ date: install.date }).then(() =>
													setValidated(true)
												);
											}}
											ariaLabel="Recevoir par mail"
										/>
									</div>

									<InstallByOrganization
										install={install}
										date={install.date}
										callback={() => setValidated(true)}
									/>
								</li>
							);
						})}
					</ul>
				)}
			</div>
		</>
	);
}
