import { Button } from '../../lib/Buttons/Button';
import './WizardEnd.scss';
import { useHistory } from 'react-router';
import React, { useContext } from 'react';
import SensorContext from '../../components/Context/Context';
import { Cover } from '../../components/Animation/Animation';
import { SensorContextType } from '../../components/Context/ContextType';
import { setContext } from '@sentry/react';
// Components

export default function WizardEnd() {
	const history = useHistory();
	const sensorContext: SensorContextType = useContext(SensorContext);

	setContext('dernier sensor sélectionné', {
		sensor: sensorContext.sensor
	});
	const onFinishHandle = () => {
		setContext('dernier sensor sélectionné', null);
		history.push('/search');
	};

	return (
		<div id="End" className="page">
			<Cover path={'/images/big/success'} />

			<div>
				<h2>Félicitations !</h2>
				<p>
					Le <strong>Capteur {sensorContext.sensor.serialNumber}</strong> est
					associé au contenant{' '}
					<strong>{sensorContext.sensor.container?.name}</strong>.
				</p>
			</div>

			<Button type="button" label="Terminer" onClick={onFinishHandle} />
		</div>
	);
}
