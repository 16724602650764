import BackButton from '../BackButton/BackButton';
import NextButton from '../NextButton/NextButton';
import './WizardFooter.scss';

type WizardFooterProps = {
	disabledNext?: boolean;
	next: any;
	nextLabel?: string;
	previousLabel?: string;
	previous?: any;
	loader?: boolean;
};
export default function WizardFooter({
	disabledNext,
	next,
	nextLabel,
	previousLabel,
	previous,
	loader
}: WizardFooterProps) {
	return (
		<footer>
			<BackButton previous={previous} label={previousLabel} />
			<NextButton
				disabled={disabledNext}
				next={next}
				label={nextLabel}
				loader={loader}
			/>
		</footer>
	);
}
