import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from 'react-router-dom';
import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import Login from './Login';
import Search from './search/Search';
import WizardStartSensor from './wizard/WizardStartSensor';
import WizardMeasure from './wizard/WizardMeasure';
import WizardContainerSelector from './wizard/WizardContainerSelector';
import WizardContainerValidator from './wizard/WizardContainerValidator';
import WizardFixation from './wizard/WizardFixation';
import WizardPhoto from './wizard/WizardPhoto';
import WizardGeolocalisation from './wizard/WizardGeolocalisation';
import WizardSummary from './wizard/WizardSummary';
import WizardEnd from './wizard/WizardEnd';
import ErrorBoundaries from '../components/ErrorBoundaries/ErrorBoundaries';
import {
	PrivateRoute,
	redirectIfNotAuthenticated
} from '../lib/Helper/RoutesHelper';
import Organization from './Organization';
import Installs from './Installs';
import {useHistory, useLocation} from "react-router";

const Faq = React.lazy(() => import('./Faq'));

export const RouteGuardContext = createContext<{to: string, from: string}>({ to: '', from: ''});

const RouteGuard = ({ children }: PropsWithChildren) => {
	const location = useLocation();
	const [route, setRoute] = useState({
		to: location.pathname,
		from: location.pathname
	});

	useEffect(()=> {
		setRoute((prev)=> ({to: location.pathname, from: prev.to}) )
	}, [location]);

	return (
		<RouteGuardContext.Provider value={route}>
			{ children }
		</RouteGuardContext.Provider>
	)
}

const CanNotActivateGuard = ({ children, to, from }: PropsWithChildren<{ to: string, from: string }>) => {
	const history = useHistory();
	const ctx = useContext(RouteGuardContext);

	useEffect(() => {
		if(ctx.to === to && ctx.from === from){
			history.push(ctx.from);
		}
	}, [ctx, to, from, history])

	return children;
}
export default function Routes() {

	return (
		<Router>
			<ErrorBoundaries>
				<RouteGuard>
				<Switch>
					<PrivateRoute path="/search" Component={Search} />
					<PrivateRoute path="/organization" Component={Organization} />
					<PrivateRoute path="/containers-list" Component={WizardContainerSelector} />
					<PrivateRoute path="/installs" Component={Installs} />
					<PrivateRoute
						path="/wizard/start_sensor"
						Component={WizardStartSensor}
					/>
					<PrivateRoute path="/wizard/measure" Component={WizardMeasure} />
					<PrivateRoute
						path="/wizard/container-selector"
						Component={WizardContainerSelector}
					/>
					<PrivateRoute
						path="/wizard/container-validation"
						Component={WizardContainerValidator}
					/>
					<PrivateRoute path="/wizard/fixation" Component={WizardFixation} />
					<PrivateRoute path="/wizard/photo" Component={WizardPhoto} />
					<PrivateRoute
						path="/wizard/geolocalisation"
						Component={WizardGeolocalisation}
					/>
					<PrivateRoute path="/wizard/summary">
						<CanNotActivateGuard from="/wizard/end" to="/wizard/summary">
							<WizardSummary />
						</CanNotActivateGuard>
					</PrivateRoute>
					<PrivateRoute path="/wizard/end">
						<CanNotActivateGuard from="/search" to="/wizard/end">
							<WizardEnd />
						</CanNotActivateGuard>
					</PrivateRoute>
					<Route path="/login">
						<Login />
					</Route>
					<Route path="/faq">
						<React.Suspense fallback={<></>}>
							<Faq />
						</React.Suspense>
					</Route>
					<Route path="/">
						{redirectIfNotAuthenticated(<Redirect to="/search" />, '/login')}
					</Route>
				</Switch>
				</RouteGuard>
			</ErrorBoundaries>
		</Router>
	);
}
