import './header.scss';
import { useHistory } from 'react-router';
import { revokeAuthentication } from '../../api/authentication';
import ArrowLeft from '../../svg/ArrowLeft';
import { slide as Menu } from 'react-burger-menu';
import { SensorContextType } from '../Context/ContextType';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import SensorContext from '../Context/Context';

export default function Header() {
	const sensorContext: SensorContextType = useContext(SensorContext);
	const history = useHistory();
	const logout = () => {
		revokeAuthentication().then(() => {
			sensorContext.setSuperInstaller(undefined);
			history.replace('/login');
		});
	};

	return (
		<header id="Header">
			<Menu>
				<ul>
					<li>
						<Link className="menu-item" to="/search">
							Installation
						</Link>
					</li>
					{(sensorContext.isSuperInstaller() || sensorContext.hasAccessToMulipleOrgs()) && (
						<li>
							<Link className="menu-item" to="/organization">
								Changer d'organisation
							</Link>
						</li>
					)}
					<li>
						<Link className="menu-item" to="/containers-list">
							Liste de contenants
						</Link>
					</li>
					<li>
						<Link className="menu-item" to="/faq">
							FAQ
						</Link>
					</li>
					<li>
						<Link className="menu-item" to="/installs">
							Rapports
						</Link>
					</li>
					<li>
						<button className="menu-item" type="button" onClick={logout}>
							Se déconnecter
						</button>
					</li>
				</ul>
			</Menu>
			<div>
				<h1>Heyliot</h1>
				<p>{sensorContext.getUserOrganizationName()}</p>
			</div>
		</header>
	);
}

type HeaderTitleWithBackButtonProps = {
	title: string;
};

export function HeaderTitleWithBackButton({
	title
}: HeaderTitleWithBackButtonProps) {
	const history = useHistory();

	return (
		<header id="HeaderTitleWithBackButton">
			<button onClick={() => history.goBack()} aria-label="Précédent">
				<ArrowLeft />
			</button>
			<h2>{title}</h2>
		</header>
	);
}
