import './ContainerList.scss';
//state
import { useHistory } from 'react-router';
import {
	KeyboardEvent,
	MutableRefObject,
	useEffect,
	useRef,
	useState
} from 'react';
import { Container } from '../../components/Context/ContextType';
//context
import { useSensorContext } from '../../components/Context/Context';
//api
import { AskForStatus } from './Container/ContainerList/AskForStatus';
import { AskForSav } from './Container/ContainerList/AskForSav';
import { ContainerActiveContractsWarning } from './Container/ContainerList/ContainerActiveContractsWarning';

import { ContainerDrawer } from './Container/ContainerList/ContainerDrawer';

import { createLogger } from '../../monitoring/logrocket';
import { UpdateForm } from './Container/ContainerForm/UpdateForm';
import { ApiGetContainer } from '../../api/model/container';
import pThrottle from 'p-throttle';
import { InputText } from '../../lib/Forms/InputText/InputText';
import { StatusSelect, TagsSelect } from '../../components/select';
import { Option } from '../../api/model';
import { Containers } from './Container/ContainerList/ContainerList';
import { useAsyncError } from '../../components/ErrorBoundaries/ErrorBoundaries';
import { useOriginCreateContainerContext } from './Container/ContainerForm/OriginCreateContainerContext';
import {
	GeolocationPositionOrigin,
	GeolocationPositionWithOrigin,
	getCurrentPosition
} from '../../api/geolocation';
import { getContainerList } from '../../api';

const logger = createLogger('Container List Page');

const ASK_TAG_STATUS = 'ASK_TAG_STATUS';
const ASK_SAV = 'ASK_SAV';
const ASK_ACTIVE_CONTRACT = 'ASK_ACTIVE_CONTRACT';
const ASK_FOR_EDITION = 'ASK_FOR_EDITION';

const throttle = pThrottle({
	limit: 2,
	interval: 300
});

export default function ContainerList() {
	const history = useHistory();

	const [askStatus, setAskStatus] = useState<string>();
	const [selectedContainer, setSelectedContainer] = useState<Container | null>(
		null
	);

	const sensorContext = useSensorContext();

	const throwError = useAsyncError();
	const previousController: MutableRefObject<AbortController | undefined> =
		useRef();

	const { organizationId } = useOriginCreateContainerContext();

	const [statusFilter, setStatusFilter] = useState<string>('TO_EQUIP');
	const [tagsFilter, setTagsFilter] = useState<string[]>([]);
	const [containerList, setContainerList] = useState<any[] | undefined>();
	const [filter, setFilter] = useState('');
	const [position, setPosition] =
		useState<GeolocationPositionWithOrigin | null>(null);

	const displayWarningMessage = (
		origin: GeolocationPositionOrigin | undefined
	) => {
		if (origin === 'localstorage') {
			throwError(new Error('geoloc_not_uptodate'));
		} else if (origin === 'unknown') {
			throwError(new Error('geoloc_unknown'));
		}
	};

	const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		const value = (e.target as HTMLInputElement).value;
		logger.log('Filtering', value);
		setFilter(value);
	};

	const getContainerListData = throttle(
		(filter: string, status: string, tags: string[]) => {
			setContainerList(undefined);
			logger.log('Fetch container List');

			if (previousController.current) {
				previousController!.current.abort();
			}

			const controller = new AbortController();
			const signal = controller.signal;
			previousController.current = controller;

			displayWarningMessage(position?.origin);

			getContainerList(
				signal,
				organizationId,
				position?.coords!,
				filter,
				status,
				tags
			).then(setContainerList);
		}
	);

	useEffect(() => {
		logger.log('Fetching Current Position');
		getCurrentPosition().then((position: GeolocationPositionWithOrigin) => {
			logger.log('Receiving Current Position', JSON.stringify(position));
			setPosition(position);
		});
	}, []);

	useEffect(
		() => {
			if (position === null) {
				logger.log(
					"Coords are not yet defined, we won't fetch the container list"
				);
				return;
			}

			logger.log('Fetching the container list');
			getContainerListData(filter, statusFilter, tagsFilter);
		}, // eslint-disable-next-line
		[position, filter, statusFilter, tagsFilter]
	);

	const replaceOldContract = (
		action: string,
		koSensors?: string[],
		tag?: string
	) => {
		if (selectedContainer) {
			//prettier-ignore
			logger.log('Replace an old Contract', action, koSensors?.join(',') ?? '', tag ?? '');
			sensorContext.setSensor({
				...sensorContext.sensor,
				removeContainerContracts: action === 'replace',
				tagKoSensors: koSensors,
				tagValueKoSensors: tag,
				container: selectedContainer
			});
			history.push('/wizard/container-validation');
		}
	};

	const onSave = (container: ApiGetContainer) => {
		setContainerList(
			containerList?.map((c) => {
				if (c.id === container.id) {
					return {
						...c,
						...container
					};
				}
				return c;
			})
		);
		setSelectedContainer({
			...selectedContainer,
			...container
		} as Container);
		setAskStatus(undefined);
	};

	return (
		<div id="container_list">
			{selectedContainer && (
				<>
					{askStatus === ASK_TAG_STATUS && (
						<AskForStatus
							container={selectedContainer}
							onChoiceSelect={({ action, serialNumbers, tag }) => {
								replaceOldContract(action, serialNumbers, tag);
								setAskStatus(undefined);
							}}
						/>
					)}

					{askStatus === ASK_SAV && (
						<AskForSav
							container={selectedContainer}
							onYes={() => {
								setAskStatus(ASK_TAG_STATUS);
							}}
							onNo={() => {
								replaceOldContract('replace');
								setAskStatus(undefined);
							}}
						/>
					)}

					{askStatus === ASK_ACTIVE_CONTRACT && (
						<ContainerActiveContractsWarning
							container={selectedContainer}
							currentSerialNumber={sensorContext.sensor.serialNumber}
							onCancel={() => {
								setAskStatus(undefined);
								setSelectedContainer(null);
							}}
							onAdd={() => {
								replaceOldContract('add');
								setAskStatus(undefined);
							}}
							onReplace={() => {
								setAskStatus(ASK_SAV);
							}}
						></ContainerActiveContractsWarning>
					)}
				</>
			)}

			{askStatus === ASK_FOR_EDITION && (
				<UpdateForm
					container={selectedContainer! as unknown as ApiGetContainer}
					onSave={onSave}
					onCancel={() => setAskStatus(undefined)}
				/>
			)}

			<div hidden={askStatus === ASK_FOR_EDITION}>
				<div
					id="ContainerSelector"
					className={!!selectedContainer ? 'disabled' : ''}
					onClick={() => setSelectedContainer(null)}
				>
					<div className="drawerShadow" />

					<div className="containerList">
						<div className="container-model-filter">
							<InputText
								placeholder="Nom du container"
								type="text"
								classModifiers={[]}
								onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => onKeyDown(e)}
							/>
							<div id="filters">
								<StatusSelect
									value={statusFilter}
									onChange={(option: Option) => setStatusFilter(option.value)}
								/>
								<TagsSelect
									onChange={(options: Option[]) =>
										setTagsFilter(options.map(({ value }) => value))
									}
								/>
							</div>
						</div>

						<Containers
							containers={containerList}
							onSelect={setSelectedContainer}
						/>
					</div>
				</div>

				{selectedContainer && (
					<ContainerDrawer
						container={selectedContainer}
						onEdit={() => setAskStatus(ASK_FOR_EDITION)}
						onConfirm={() => {
							if (selectedContainer.activeContracts.length > 0) {
								setAskStatus(ASK_ACTIVE_CONTRACT);
							} else {
								sensorContext.setSensor({
									...sensorContext.sensor,
									container: selectedContainer
								});
								history.push('/wizard/container-validation');
							}
						}}
					></ContainerDrawer>
				)}
			</div>
		</div>
	);
}
