import './Loading.scss';

type LoadingProps = {
	text?: string;
};
export default function Loading({ text = 'Chargement' }: LoadingProps) {
	return (
		<div className="Loader">
			<div className="lds-roller">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
			<p>{text}</p>
		</div>
	);
}
