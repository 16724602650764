import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { PrivateRoute } from '../../../lib/Helper/RoutesHelper';
import ContainerList from '../ContainerList';
import React from 'react';
import { CreationForm } from './ContainerForm/CreationForm';
import { ContainerFormDataContext } from './ContainerFormDataContext';
import { useModels } from './ContainerForm/models/useModels';
import { useGroups } from './ContainerForm/groups/useGroups';
import { useFlows } from './ContainerForm/flows/useFlows';
import { useDepositPoints } from './ContainerForm/deposit/useDepositPoints';

export const ContainerRoutes = () => {
	let { path } = useRouteMatch();
	const models = useModels();
	const groups = useGroups();
	const flows = useFlows();
	const depositPoints = useDepositPoints();

	return (
		<ContainerFormDataContext.Provider
			value={{ models, groups, flows, depositPoints }}
		>
			<Switch>
				<Route exact path={`${path}`}>
					<Redirect to={`${path}/list`} />
				</Route>
				<PrivateRoute path={`${path}/list`} Component={ContainerList} />
				<PrivateRoute path={`${path}/create`} Component={CreationForm} />
			</Switch>
		</ContainerFormDataContext.Provider>
	);
};
