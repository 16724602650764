import { Container, Flows } from '../../../../components/Context/ContextType';
import Tag, { ContainerTag, StatusTag } from '../../../../components/Tag';
import { ContainerFlowTag } from '../../../../components/Tag/ContainerFlowTag';
import { ICONS } from '../../../../components/Icons/icons';
import React from 'react';
import './ContainerListItem.scss';
import { ActiveContractsList } from './ActiveContractsList';

type ContainerListItemProps = {
	container: Container;
	flows?: Flows;
};
export const ContainerListItem = ({
	container,
	flows
}: Readonly<ContainerListItemProps>) => {
	return (
		<>
			<h3>{container.name}</h3>
			{!!container.depositPoint && (
				<Tag
					label={container.depositPoint?.name}
					classModifiers={['default-status']}
					iconLeft={ICONS.CIRCLE}
				/>
			)}

			<StatusTag logisticStatus={container.logisticStatus} />
			<ContainerTag tags={container.tags} />

			{container.flow && (
				<ContainerFlowTag flow={container.flow} flows={flows!} />
			)}
			<ActiveContractsList contracts={container.activeContracts} />
		</>
	);
};
