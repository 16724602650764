import { Options } from '../../../../../api/model';

export const tagsOptions: Options = [
	{ value: 'container-broken', label: 'Contenant SAV' },
	{ value: 'container-damaged', label: 'Contenant endommagé' },
	{ value: 'not-found', label: 'Contenant introuvable' },
	{ value: 'container-no-network', label: 'Contenant sans réseau' },
	{ value: 'sensor-wrong-measure', label: 'Sensor à nettoyer' },
	{ value: 'sensor-no-comm', label: 'Sensor ne communique pas' }
];
