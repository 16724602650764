// REACT
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import './WizardPhoto.scss';
// @ts-ignore
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
// COMPONENTS
import WizardFooter from '../../components/Footer/WizardFooter';
import WizardStepper from '../../components/WizardStepper/WizardStepper';
import RetryButton from '../../components/RetryButton/RetryButton';
import { Cover } from '../../components/Animation/Animation';
import ArrowLeft from '../../svg/ArrowLeft';
import { FlashComponent } from '../../components/FlashLight/FlashLight';
import useFlash from '../../components/Hook/useFlash';
import {
	resizeReactCameraPicture,
	dataURItoBlob,
	toBuffer
} from '../../lib/Helper/PhotoHelper';
// CONTEXT
import SensorContext from '../../components/Context/Context';
import { SensorContextType } from '../../components/Context/ContextType';
// EXTERNAL LIB
// @ts-ignore

// CSS
import 'react-html5-camera-photo/build/css/index.css';
import './WizardPhoto.scss';

import * as Minio from 'minio';
import { UploadedObjectInfo } from 'minio';

const minioClient = new Minio.Client({
	endPoint: process.env.REACT_APP_HEY_MINIO_ENDPOINT,
	accessKey: process.env.REACT_APP_HEY_MINIO_ACCESS_KEY,
	secretKey: process.env.REACT_APP_HEY_MINIO_SECRET_KEY,
	port: 443,
	useSSL: true
});

export default function WizardPhoto() {
	const history = useHistory();
	const [photoMode, setPhotoMode] = useState<boolean>(false);
	const [photoUri, setPhotoUri] = useState<string | null>(null);
	const sensorContext: SensorContextType = useContext(SensorContext);
	const [flash, toggleFlash] = useFlash();
	const [photoBlock, setPhotoBlock] = useState<any>();
	const [photoMessageError, setPhotoMessageError] = useState<any>();
	const [loading, setLoading] = useState(false);

	const savePhotoInAzure = async () => {
		setLoading(true);
		if (!loading) {
			const random = Math.floor(Math.random() * (10 - 0 + 1));
			const name = `/${
				sensorContext.sensor.organizationObject.id
			}/${new Date().getTime()}-${random}.jpg`;

			const arrayBuffer = await dataURItoBlob(photoUri!).arrayBuffer();
			minioClient.putObject(
				process.env.REACT_APP_HEY_MINIO_BUCKET,
				name,
				toBuffer(arrayBuffer),
				(error: Error, uploadedObjectInfo: UploadedObjectInfo) => {
					sensorContext.setSensor({
						...sensorContext.sensor,
						container: {
							...sensorContext.sensor.container,
							picture: `${process.env.REACT_APP_HEY_MEDIA_API}${name}`
						}
					});
					setLoading(false);
					history.push('/wizard/geolocalisation');
				}
			);
		}
	};

	const resizeImage = useCallback(resizeReactCameraPicture, []);

	useEffect(() => {
		const handleCameraError = (error: any) => {
			if (
				error.name === 'NotReadableError' ||
				error.name === 'TrackStartError'
			) {
				setPhotoMessageError(
					"Malgré l'autorisation d'utiliser votre caméra, le navigateur rencontre des difficultés à l'utiliser. Merci de vous assurer que votre matériel n'est pas utilisé par une autre application."
				);
			} else if (
				error.name === 'NotAllowedError' ||
				error.name === 'PermissionDeniedError'
			) {
				setPhotoMessageError(
					"Votre caméra ne semble pas disponible. Vérifiez que votre navigateur a les droits d'accès à votre caméra. Sinon, cliquez sur suivant."
				);
			} else {
				setPhotoMessageError(
					"L'application rencontre une erreur avec votre caméra."
				);
			}

			setPhotoBlock(
				<div hidden={!photoMode} id="PhotoOff" className="page wizard-page">
					<Cover path={'/images/big/take_picture_error'} />

					<div className="wizard-page__content">
						<h2>Prendre une photo</h2>
						<p>{photoMessageError}</p>
						<RetryButton
							onClick={() => {
								setPhotoMode(false);
								setPhotoBlock(null);
							}}
						/>
					</div>
					<WizardStepper activeIndex={6} />
					<WizardFooter
						previous={() => {
							history.push('/wizard/measure?measure=indoor');
						}}
						next={() => {
							history.goBack()
						}}
					/>
				</div>
			);
		};

		//check if camera is available else throw error. if available kill started stream and launch camera
		if (photoMode) {
			navigator.mediaDevices
				.getUserMedia({ video: true })
				.then((stream) => {
					stream.getTracks().forEach(function (track) {
						track.stop();
					});
					setPhotoBlock(
						<div id="PhotoOn" hidden={!photoMode}>
							{camera}
						</div>
					);
				})
				.catch((err) => {
					handleCameraError(err);
				});
		}

		const camera = (
			<>
				{
					<button
						onClick={() => {
							setPhotoMode(false);
							toggleFlash();
							setPhotoBlock(null);
						}}
						className="back"
						aria-label="Revenir en arrière"
					>
						<ArrowLeft />
					</button>
				}
				<FlashComponent flash={flash} toggle={toggleFlash} />
				<Camera
					isImageMirror={false}
					imageType={IMAGE_TYPES.JPG}
					idealResolution={{
						width: window.innerWidth,
						height: window.innerWidth
					}}
					isMaxResolution={false}
					idealFacingMode={FACING_MODES.ENVIRONMENT}
					isFullscreen={false}
					onTakePhoto={async (dataUri: string) => {
						const resize = await resizeImage(dataUri);
						setPhotoUri(resize);
						setPhotoMode(false);
					}}
					isDisplayStartCameraError={true}
					onCameraError={(error: any) => {
						handleCameraError(error);
					}}
				/>
			</>
		);
	}, [photoMode, flash, toggleFlash, history, photoMessageError, resizeImage]);

	// Check if user is satisfied with picture
	if (photoUri && !photoMode) {
		return (
			<>
				<div className="picture-render">
					<img id="taken-picture" src={photoUri} alt="" />
				</div>
				<div id="PhotoOff" className="page Visualisation wizard-page">
					<div className="wizard-page__content wizard-page__content_small">
						<h2>Satisfait ?</h2>
						<p>Etes-vous satisfait de votre photo ?</p>
						<RetryButton
							onClick={() => {
								setPhotoBlock(null);
								setPhotoMode(true);
							}}
						/>
					</div>
					<WizardStepper activeIndex={6} />
					<WizardFooter
						previous={() => history.goBack()}
						next={savePhotoInAzure}
						disabledNext={loading}
						loader={loading}
					/>
				</div>
			</>
		);
	}

	return (
		<>
			{photoBlock}
			{!photoMode && (
				<div id="PhotoOff" className="page wizard-page">
					<Cover path={'/images/big/take_picture'} />

					<button
						id="Pass"
						onClick={() => history.push('/wizard/geolocalisation')}
					>
						Passer
					</button>
					<div className="wizard-page__content">
						<h2>Prendre une photo</h2>
						<p>
							La photo du contenant permet de mieux le reconnaître pour les
							futures installations ou remplacements.
						</p>
						<p>Votre photo doit respecter les préconisations suivantes :</p>
						<ul>
							<li>
								<img src="/images/check.svg" alt="" />
								L'ensemble du contenant est visible
							</li>
							<li>
								<img src="/images/check.svg" alt="" />
								L'exposition de votre photo est adaptée
							</li>
						</ul>
					</div>
					<WizardStepper activeIndex={7} />
					<WizardFooter
						next={() => {
							setPhotoMode(true);
						}}
						previous={() => history.goBack()}
						loader={loading}
					/>
				</div>
			)}
		</>
	);
}
