import {Sensor} from "../../../components/Context/ContextType";

export const Information = ({ sensor }: { sensor: Sensor}) => {
    if(sensor.tags?.includes("/v2/tags/sensor-button-control")){
        return (
            <p>
                Faites un appui court sur le bouton du capteur pour prendre une mesure. Puis cliquer sur suivant.
            </p>
        )
    }

    return (
        <p>
            Une fois le réseau sélectionné, déposez votre aimant sur le pictogramme pendant un court instant pour déclencher une mesure (5 secondes retardateur, puis prise de mesure). Puis cliquez sur suivant.
        </p>
    )


}
