import { useEffect, useState } from 'react';
import { fetchAllFlows } from '../../../../../api/flows';
import { useOriginCreateContainerContext } from '../OriginCreateContainerContext';
import { ApiGetFlow } from '../../../../../api/model/container';

export const useFlows = () => {
	const [flows, setFlows] = useState<ApiGetFlow[]>([]);

	const { organizationId } = useOriginCreateContainerContext();

	useEffect(() => {
		fetchAllFlows(organizationId).then((flows) => {
			setFlows(flows);
		});
	}, [organizationId]);

	return flows;
};
