import { PropsWithChildren, useLayoutEffect, useRef } from 'react';
import './OfflineScreen.scss';

type OfflineScreenProps = {};
export const OfflineScreen = ({
	children
}: PropsWithChildren<OfflineScreenProps>) => {
	const counter = useRef<number>(0);
	const intervalId = useRef<NodeJS.Timer | undefined>();
	const offscreen = useRef<HTMLDivElement | null>(null);
	const application = useRef<HTMLDivElement | null>(null);

	const offlineHandler = () => {
		application.current?.classList.add('blur');
		offscreen.current?.classList.add('offline-screen');

		const timeout = parseInt(process.env.REACT_APP_OFFLINE_TIMEOUT ?? '30000');
		const interval = parseInt(process.env.REACT_APP_OFFLINE_INTERVAL ?? '5000');
		counter.current = timeout / interval - 1;

		intervalId.current = setInterval(() => {
			if (counter.current === 0 && !!intervalId.current) {
				clearInterval(intervalId.current);
				offscreen.current?.querySelector('#timeout')?.removeAttribute('hidden');
				offscreen.current?.querySelector('#retry')?.setAttribute('hidden', '');
			} else {
				counter.current = counter.current - 1;
			}
		}, interval);
	};

	const onlineHandler = () => {
		if (!!intervalId.current) {
			clearInterval(intervalId.current);
		}
		application.current?.classList.remove('blur');
		offscreen.current?.classList.remove('offline-screen');
		offscreen.current?.querySelector('#retry')?.removeAttribute('hidden');
		offscreen.current?.querySelector('#timeout')?.setAttribute('hidden', '');
		offscreen.current?.classList.add('offline-screen-hidden');
	};

	useLayoutEffect(() => {
		window.addEventListener('offline', offlineHandler);
		window.addEventListener('online', onlineHandler);

		return () => {
			window.removeEventListener('offline', offlineHandler);
			window.removeEventListener('online', onlineHandler);
		};
	}, []);

	return (
		<>
			<div ref={offscreen} className="offline-screen-hidden">
				<div className="logo-name-container"> Heyliot Installer </div>
				<div className="homescreen-text" id="retry">
					Vous avez perdu le réseau. Nous allons tenter une nouvelle
					connexion...
				</div>
				<div className="homescreen-text" id="timeout" hidden>
					La connexion n'est pas revenu dans une durée appropriée. Veuillez
					essayer de nouveau ultérieurement.
				</div>
				<div className="icon-homePointer sprite-mobile"></div>
			</div>
			<div ref={application}>{children}</div>
		</>
	);
};
