export default function QRCode() {
	return (
		<svg
			id="qr-code"
			xmlns="http://www.w3.org/2000/svg"
			width="30.203"
			height="30.203"
			viewBox="0 0 30.203 30.203"
		>
			<path
				id="Tracé_517"
				data-name="Tracé 517"
				d="M286.59,267.18a.59.59,0,1,0-.59-.59A.59.59,0,0,0,286.59,267.18Zm0,0"
				transform="translate(-269.129 -250.308)"
			/>
			<path
				id="Tracé_518"
				data-name="Tracé 518"
				d="M66.59,267.18a.59.59,0,1,0-.59-.59A.59.59,0,0,0,66.59,267.18Zm0,0"
				transform="translate(-62.107 -250.308)"
			/>
			<path
				id="Tracé_519"
				data-name="Tracé 519"
				d="M.59,7.433a.59.59,0,0,0,.59-.59V1.18H6.843a.59.59,0,0,0,0-1.18H.59A.59.59,0,0,0,0,.59V6.843A.59.59,0,0,0,.59,7.433Zm0,0"
			/>
			<path
				id="Tracé_520"
				data-name="Tracé 520"
				d="M7.433,392.843a.59.59,0,0,0-.59-.59H1.18V386.59a.59.59,0,0,0-1.18,0v6.253a.59.59,0,0,0,.59.59H6.843A.59.59,0,0,0,7.433,392.843Zm0,0"
				transform="translate(0 -363.229)"
			/>
			<path
				id="Tracé_521"
				data-name="Tracé 521"
				d="M392.843,386a.59.59,0,0,0-.59.59v5.663H386.59a.59.59,0,0,0,0,1.18h6.253a.59.59,0,0,0,.59-.59V386.59A.59.59,0,0,0,392.843,386Zm0,0"
				transform="translate(-363.229 -363.229)"
			/>
			<path
				id="Tracé_522"
				data-name="Tracé 522"
				d="M392.843,0H386.59a.59.59,0,0,0,0,1.18h5.663V6.843a.59.59,0,0,0,1.18,0V.59A.59.59,0,0,0,392.843,0Zm0,0"
				transform="translate(-363.229)"
			/>
			<path
				id="Tracé_523"
				data-name="Tracé 523"
				d="M66,332.489a.59.59,0,0,0,.59.59h5.9a.59.59,0,0,0,.59-.59v-5.9a.59.59,0,0,0-.59-.59h-5.9a.59.59,0,0,0-.59.59Zm1.18-5.309H71.9V331.9H67.18Zm0,0"
				transform="translate(-62.107 -306.769)"
			/>
			<path
				id="Tracé_524"
				data-name="Tracé 524"
				d="M72.489,66h-5.9a.59.59,0,0,0-.59.59v5.9a.59.59,0,0,0,.59.59h5.9a.59.59,0,0,0,.59-.59v-5.9A.59.59,0,0,0,72.489,66Zm-.59,5.9H67.18V67.18H71.9Zm0,0"
				transform="translate(-62.107 -62.107)"
			/>
			<path
				id="Tracé_525"
				data-name="Tracé 525"
				d="M333.079,66.59a.59.59,0,0,0-.59-.59h-5.9a.59.59,0,0,0-.59.59v5.9a.59.59,0,0,0,.59.59h5.9a.59.59,0,0,0,.59-.59ZM331.9,71.9H327.18V67.18H331.9Zm0,0"
				transform="translate(-306.769 -62.107)"
			/>
			<path
				id="Tracé_526"
				data-name="Tracé 526"
				d="M333.079,332.489v-5.9a.59.59,0,0,0-.59-.59h-5.9a.59.59,0,0,0-.59.59v5.9a.59.59,0,0,0,.59.59h5.9A.59.59,0,0,0,333.079,332.489Zm-1.18-.59H327.18V327.18H331.9Zm0,0"
				transform="translate(-306.769 -306.769)"
			/>
			<path
				id="Tracé_527"
				data-name="Tracé 527"
				d="M107.77,366h-1.18a.59.59,0,0,0-.59.59v1.18a.59.59,0,0,0,.59.59h1.18a.59.59,0,0,0,.59-.59v-1.18A.59.59,0,0,0,107.77,366Zm0,0"
				transform="translate(-99.747 -344.409)"
			/>
			<path
				id="Tracé_528"
				data-name="Tracé 528"
				d="M106.59,108.36h1.18a.59.59,0,0,0,.59-.59v-1.18a.59.59,0,0,0-.59-.59h-1.18a.59.59,0,0,0-.59.59v1.18A.59.59,0,0,0,106.59,108.36Zm0,0"
				transform="translate(-99.747 -99.747)"
			/>
			<path
				id="Tracé_529"
				data-name="Tracé 529"
				d="M366.59,108.36h1.18a.59.59,0,0,0,.59-.59v-1.18a.59.59,0,0,0-.59-.59h-1.18a.59.59,0,0,0-.59.59v1.18A.59.59,0,0,0,366.59,108.36Zm0,0"
				transform="translate(-344.409 -99.747)"
			/>
			<path
				id="Tracé_530"
				data-name="Tracé 530"
				d="M366.59,368.36h1.18a.59.59,0,0,0,.59-.59v-1.18a.59.59,0,0,0-.59-.59h-1.18a.59.59,0,0,0-.59.59v1.18A.59.59,0,0,0,366.59,368.36Zm0,0"
				transform="translate(-344.409 -344.409)"
			/>
			<path
				id="Tracé_531"
				data-name="Tracé 531"
				d="M106,266.59a.59.59,0,0,0,.59.59h7.669v2.95a.59.59,0,1,0,1.18,0V266.59a.59.59,0,0,0-.59-.59H106.59A.59.59,0,0,0,106,266.59Zm0,0"
				transform="translate(-99.747 -250.308)"
			/>
			<path
				id="Tracé_532"
				data-name="Tracé 532"
				d="M72.489,227.18a.59.59,0,1,0,0-1.18h-5.9a.59.59,0,0,0,0,1.18Zm0,0"
				transform="translate(-62.107 -212.668)"
			/>
			<path
				id="Tracé_533"
				data-name="Tracé 533"
				d="M206.59,227.18h2.36a.59.59,0,0,0,0-1.18h-2.36a.59.59,0,0,0,0,1.18Zm0,0"
				transform="translate(-193.848 -212.668)"
			/>
			<path
				id="Tracé_534"
				data-name="Tracé 534"
				d="M206.59,66a.59.59,0,0,0-.59.59v5.9a.59.59,0,0,0,.59.59h4.129v2.95a.59.59,0,0,0,.59.59h2.36a.59.59,0,0,0,0-1.18H211.9v-2.95a.59.59,0,0,0-.59-.59H207.18V66.59A.59.59,0,0,0,206.59,66Zm0,0"
				transform="translate(-193.848 -62.107)"
			/>
			<path
				id="Tracé_535"
				data-name="Tracé 535"
				d="M370.129,226H366.59a.59.59,0,0,0,0,1.18h3.539a.59.59,0,1,0,0-1.18Zm0,0"
				transform="translate(-344.409 -212.668)"
			/>
			<path
				id="Tracé_536"
				data-name="Tracé 536"
				d="M332.489,266h-5.9a.59.59,0,0,0,0,1.18h5.9a.59.59,0,1,0,0-1.18Zm0,0"
				transform="translate(-306.769 -250.308)"
			/>
			<path
				id="Tracé_537"
				data-name="Tracé 537"
				d="M208.95,366h-2.36a.59.59,0,0,0-.59.59v3.539a.59.59,0,1,0,1.18,0v-2.95h1.77a.59.59,0,1,0,0-1.18Zm0,0"
				transform="translate(-193.848 -344.409)"
			/>
			<path
				id="Tracé_538"
				data-name="Tracé 538"
				d="M248.36,313.079h-1.77a.59.59,0,0,0,0,1.18h2.36a.59.59,0,0,0,.59-.59V306.59a.59.59,0,0,0-1.18,0Zm0,0"
				transform="translate(-231.488 -287.949)"
			/>
			<path
				id="Tracé_539"
				data-name="Tracé 539"
				d="M248.95,70.719a.59.59,0,0,0,.59-.59V66.59a.59.59,0,0,0-1.18,0v2.95h-1.77a.59.59,0,0,0,0,1.18Zm0,0"
				transform="translate(-231.488 -62.107)"
			/>
		</svg>
	);
}
